import React, { useContext } from 'react'
import commonLib from '../control/commonLib'
import { UserContext } from '../App';


export default function EventHeader(props) {
    const { subtenantLogo } = useContext(UserContext);
    const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
    const { subtenantId } = props;


    return (
        <div>
            <div class="container p-2 w-100 h-20">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="">
                        <img className='' style={{maxWidth: '200px'}} src={subtenantLogo} />
                    </div>
                    {
                        (parseInt(subtenantId) !== 26) &&
                            <div class="d-flex align-items-center">
                                {/* <img src='/icons/avatarIcon.svg' className="me-2" style={{ width: 50 }} /> */}
                                <div className='text-start d-none d-sm-block'>
                                    {/* <h6 className='m-0'>Welcome {attendeeObj?.attendeeFullName}</h6> */}
                                    {/* <p className='m-0' style={{ color: '#4FA6D5' }}>Update your Profile</p> */}
                                </div>
                                <div className='align-self-sm-start mt-sm-1'>
                                    {/* <img src='/icons/imageMenuDownIcon.svg' /> */}
                                </div>
                            </div>   
                    }
                </div>
            </div>
        </div>
    )
};

import React, { useState, useRef, useEffect ,useContext} from 'react';
import Services from '../../../network/Services';
import commonLib from '../../../control/commonLib';
import konsole from '../../../network/konsole';
import { UserContext } from "../../../App";
import { BASE_URL_PAY, BASE_URL_PROD_PAY, EventBaseUrl } from '../../../network/url';
const Payment=(props)=>{

const clickbuttonref=useRef()
let appstate = commonLib.getObjFromStorage('stateObj')?.appState
const [orderId, setorderId] = useState('')
const {setLoader} = useContext(UserContext);
const seminarObj = commonLib.getObjFromStorage('seminarObj');


useEffect(() => {
    adduserApiCall()

  }, [])

useEffect(() => {
  
    if (orderId !== '') {
      clickbuttonref.current.submit()
      commonLib.setDatatoSession("countRenderData", JSON.stringify(true))
      setLoader(false)
    }
 }, [orderId])

 

  const adduserApiCall = () => {
    let adduserobj = {
      "userId": props?.selectedUser?.post2 ? props?.selectedUser?.post2?.userId : props?.classData?.classRoomData?.classBooking == "classBooking" 
        ? props?.attendeeObj?.seminarAttendeeUserId: props?.attendeeObj?.seminarAttendeeUserId,
     
      "shippingCost": 0,
      "taxCost": 0,
      "totalCost": props?.selectedUser?.post2 ? props?.selectedUser?.semObj?.seminarFee : props?.classData?.classRoomData?.classBooking == "classBooking" 
       ? props?.classData?.classRoomData?.seminarFee : 350,
    
     
      "productList": [
        {
          "productType": props?.selectedUser?.post2 ? "SEMINAR" : props?.classData?.classRoomData?.classBooking == "classBooking"  
          ? "CLASSROOM" : "PLANNER",

          "productId": props?.selectedUser?.post2 ? props?.selectedUser?.post2?.seminarId : props?.classData?.classRoomData?.classBooking == "classBooking"  
          ? props?.classData?.classRoomData.seminarId : props?.seminarObj?.seminarId,

          "productName" : props?.selectedUser?.post2 ?  seminarObj?.seminarTopic : props?.classData?.classRoomData?.classBooking == "classBooking" ? props?.classData?.classRoomData?.seminarTopic : "Initial Consultation"  ,
          "quantity": props?.selectedUser?.post2 ? props?.selectedUser?.post?.noOfAttendee : props?.classData?.classRoomData?.classBooking == "classBooking" 
          ? 1 : 1,
    
          "productPrice": props?.selectedUser?.post2 ? props?.selectedUser?.semObj?.seminarFee : props?.classData?.classRoomData?.classBooking == "classBooking" 
          ? props?.classData?.classRoomData?.seminarFee : 700,
          }]}

    let postadduser = Services.postuserorderAddUserOrder(adduserobj)
    postadduser.then((res) => {
     
      setorderId(res.data.data.order.orderId)
      
      konsole.log("responseresponse", res)

    }).catch((err) => {
      konsole.log("err", err)
    })
  }


  const getSendCheckInLinktoUser =(semId)=>{
    // konsole.log("functionseinarid",semId)
    let promise = Services.getSendCheckInLinktoUser(semId,1);
    promise.then(res=>{
      konsole.log("SeminarId1233",res);
    })
  }

 return(
    <>
     <form action={BASE_URL_PAY}
          method="post" ref={clickbuttonref} >

          <div>
            <input type="hidden" name="AppState" id="AppState" value={appstate} />
            <input type="hidden" name="ReturnUrl" id="ReturnUrl"
              value= 
              {props?.selectedUser?.post2 ? `${EventBaseUrl}Congratulations`: props?.classData?.classRoomData?.classBooking == "classBooking"
               ? `${EventBaseUrl}ConfirmBooking` : `${EventBaseUrl}ConfirmBooking`
              }
              
                />
            <input type="hidden" name="UserId" value = {props?.selectedUser?.post2 ? props?.selectedUser?.post2?.userId: props?.classData?.classRoomData?.classBooking == "classBooking"
               ? props?.attendeeObj?.seminarAttendeeUserId : props?.attendeeObj?.seminarAttendeeUserId}/>
            <input type="hidden" name="OrderId" value={orderId} />
          </div>
          <div>
          </div>
        </form>

    </>
 )   
}
export default Payment
import './Footer.css'

const Footer =() => {
    return ( 
        <div className='w-100 contentBackgroundColor d-flex justify-content-between align-items-center footerHeightmb-0 pb-0' style={{height:"10vh"}}>
            <div className='ms-2 text-danger'>©Copyright</div>
            <div className='me-2 pb-1'>
                <h6 className='text-center' >Powered By</h6> 
                <img src='/aoLogo.png' alt='footer' style={{width:"150px"}}></img>
            </div>
        </div>
     );
}
 
export default Footer;
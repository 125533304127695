
import React, { useState, useRef,useCallback,useEffect,useContext } from 'react';
import { Row, Col, Typography, Button } from "antd";
import "./readmore.css";
import EnquiryRegistration from '../EnquiryRegistration';
import ModalComponent from '../../Modal/ModalComponent';
import EventHeader from '../../SeminarListingHeader';
import SeminarListingFooter from '../../SeminarListingFooter';
import { useHistory,useParams } from 'react-router-dom';
import Services from '../../../network/Services';
import { UserContext } from '../../../App';

import commonLib from '../../../control/commonLib';

import EventMenu from '../../EventListing/EventMenu';


const { Title, Text } = Typography;


const Readmore = (props) => {
    const history = useHistory();
    const [modelShow, setModalShow] = useState(false);
    const { setLoader, setSubtenantLogo } = useContext(UserContext);
    const [showLess, setShowLess] = useState(false)
    const [seminarObj, setSeminarObj] = useState([])
    const [readMoreProfile, setReadMoreProfile] = useState(null)
    const { subtenantKey,seminarid } = useParams();


    useEffect(() => {
         getSeminarPublish(subtenantKey,true,"UPCOMING",seminarid)
       },[])
    
    
    const data1 = (seminar, toDate) => {
      var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var d = new Date(seminar);
      var dayName = days[d.getDay()];
      var d2 = new Date(toDate);
      var dayName2 = days[d2.getDay()];
      return "(" + dayName + "-" + dayName2 + ")"
  }
    const topContainerRef = useRef(null);
    const buttonId = useRef("readMore");
 
    const showModal = () => {
        setModalShow(!modelShow);
    }
 
   
    
    const getSeminarPublish = useCallback((subtenantId,isActive,seminarType,seminarId) => {
        setLoader(true)
        return new Promise((resolve, reject) => {
            Services.getSeminarPublisherDetailID(subtenantId,isActive,seminarType,seminarId).then((res)=>{
                let SeminarData = res.data.data
                if(SeminarData.length > 0){
                    Services.getSubtenantDetail(subtenantId, isActive).then((res)=>{
                        let data = res.data.data
                        if(data.length > 0){
                            commonLib.setSessionStoarge('seminarObj', JSON.stringify(data[0]));
                            setSubtenantLogo(data[0]?.subtenantLogoUrl)
                        }
                       
                    })
                    setSeminarObj(SeminarData)
                    setLoader(false)
                }
                
                 resolve(SeminarData)
           
            }).catch((err)=>{
               resolve('err')
               setLoader(false)
            })
        })
      
    }, [])

    const registerNow = (data) => {
        history.push({ pathname: '/Register', state: { seminarobj: data } })

    }
    const filterSpeakerDescriptionLegth = (dataSpeakerProfile) => {
        return dataSpeakerProfile.substr(0, 100);
    };
    const showFullProfile = (items, index,speakers1) => {
        setReadMoreProfile(items?.speakerUserId)
        let setAtZero = [];
        let filterData = [];
        filterData = speakers1.splice(index, 1);
        setAtZero = speakers1.unshift(items);
        setShowLess(true)
    }
    const goBack =(id)=>{
        history.replace({ pathname: `/eventlisting/${id}` })
    }

    

    return (
        <>
        
        { seminarObj.length > 0 && seminarObj.map((data)=>{
                const toDate =  data?.remarks?.slice(0, 11);
                const toTime =  data?.remarks?.slice(11, 31);
                const weekDay = data?.remarks?.slice(20, 40)
                return (
                <>
                   <div className='container-fluid p-0 bg-white' ref={topContainerRef}>
                   <EventHeader subtenantId={data?.subtenantId}seminarObjData={data?.subtenantLogoUrl} />
                   <EventMenu subtenantId=  {data?.subtenantId} />
                   {(data?.subtenantId == 26) ?
                    <div className=' py-2' style={{ backgroundColor: 'rgb(164, 164,164, .2)' }}>
                        <div className='row' >
                            <div className='col'>
                                <div className='row '>
                                    <div className='col d-flex justify-content-center'>
                                        <h1 style={{ color: "maroon" }}>Dinner with Rajiv</h1>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col text-center'>
                                        <h5 className="">What legacy will you leave behind? Learn how you can be part of revolutionizing the way Americans plan for retirement.</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    :
                    <div className='container-fluid py-2' style={{ backgroundColor: 'rgb(164, 164,164, .2)' }}>
                        <div className='row' >
                            <div className='col'>
                                <div className='row '>
                                    <div className='col d-flex justify-content-center'>
                                        {
                                            (data?.subtenantId === 745) ?
                                                <>
                                                    <h1 style={{ color: "maroon" }}>Live Workshop</h1>
                                                </>
                                                :
                                                <>
                                                    <h1 style={{ color: "maroon" }}>Live Events</h1>
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col d-flex justify-content-center'>
                                        <h2>How to age on your terms</h2>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-7 m-auto ' style={{ textAlign: "center" }}>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    }
                        <div className='container pb-2'>
                            <Row >
                                <Col span={24} >
                                    <Title level={5} className="cursor-pointer" onClick={() => goBack(data?.subtenantId)}>{`<`}{data?.subtenantId == 745 ? "All Workshops" : "All Events"}</Title>
                                </Col>
                            </Row>

                            <div style={{ padding: 10, border: "1px dotted black" }}>
                                <Row>
                                    <Col xs={24} sm={18} md={18} lg={18} xl={20} >
                                        <Title level={1} className="Modal-Retire-Life-Tag" style={{ color: "#B06A0A", marginBottom: 4 }}>{data?.seminarTopic}
                                            <Text className='fs-4 ms-2' style={{ color: "black" }}>
                                                {
                                                    data?.subtenantId !== 26 &&
                                                    <span classname="ms-2 " style={{ color: "black", fontSize: "15px" }}>({data?.seminarType}, {data?.isSeminarPaid == true ? 'Paid' : 'Free'}{data?.isSeminarPaid == true ? " : " + "$" + data?.seminarFee : ""})</span>
                                                }
                                            </Text>
                                        </Title>

                                    </Col>
                                    {
                                        data?.subtenantId == 26 &&
                                        <Col span={24} md={6} className='d-flex justify-content-center justify-content-lg-end my-2'>
                                            <button type="button" className="btn p-0 px-md-2 btn-outline-dark register-btn " onClick={showModal}>Register Now</button>
                                        </Col>
                                    }
                                </Row>

                                <Row >
                                    <Col span={24}>
                                        <Title level={3} style={{ marginBottom: 4, color: "#707070" }}>By :

                                            {
                                                data?.speakers?.length > 0 && data?.speakers.map((speaker, index) => {
                                                    const fName = (speaker.fName !== null && speaker.fName !== '') ? speaker.fName : '';
                                                    const mName = (speaker.mName !== null && speaker.mName !== '') ? speaker.mName : ' ';
                                                    const lName = (speaker.lName !== null && speaker.fName !== '') ? speaker.lName : '';
                                                    return <span style={{ color: "#720C20", fontSize: "24px" }}>
                                                        {` ${fName + " " + mName + " " + lName} ${(data?.speakers.length - 1 === index) ? '' : ', '}`}
                                                    </span>
                                                })
                                            }
                                        </Title>
                                    </Col>

                                </Row>
                                <Row>

                                    <Col span={24}>
                                        <Title level={4} className="m-0" style={{ color: "#707070" }}>On {" "}
                                            {data?.subtenantId == 745 ? (`${commonLib.getFormattedDate(data?.seminarDate)} To ${commonLib.getFormattedDate(toDate)} ${weekDay == 1 ? "(Sat-Sun)"
                                                        : data1(data?.seminarDate, toDate, weekDay)}  At ${commonLib.getFormattedTime(data?.seminarTime)}
                                                   To ${commonLib.getFormattedTime(toTime)}`)
                                                : (`${commonLib.getFormattedDate(data?.seminarDate)}, at ${commonLib.getFormattedTime(data?.seminarTime)}`)}
                                        </Title>
                                    </Col>
                                </Row>
                                <Row className='m-0 mb-2'>
                                    {
                                        (seminarObj?.seminarType === "Webinar") ?
                                            <></>
                                            :
                                            <>
                                                <Col span={24}>
                                                    <Title level={4} style={{ color: "#707070", margin: 0, padding: 0 }}>Venue : {data?.seminarVenue}</Title>
                                                    <Title level={4} style={{ color: "#707070", margin: 0, padding: 0 }}>Location : {data?.venueAddressLine1}</Title>
                                                </Col>
                                            </>
                                    }
                                </Row>
                                {
                                    (data?.subtenantId !== 26) ?
                                        <>
                                            <Row>
                                                <Col span={24} className="">
                                                    <Text level={1} className="fs-6">

                                                        <p>

                                                            <span className="ms-2 fs-4  fw-bold seminartxt">{data?.speakers?.length > 1 ? "Speakers" : "Speaker"}</span>
                                                        </p>

                                                    </Text>

                                                </Col>
                                                <Col span={4} xs={24} sm={24} md={4} lg={4} xl={3} className="m-0 mb-2 me-sm-3">
                                                    <div className='eventListingImage'>

                                                    </div>
                                                </Col>
                                                <Col span={24} className="mb-4">
                                                    <div className={`${showLess == true ? "readMorewillgrid" : "speaker-grid"}`}>
                                                        {data?.speakers?.length > 0 && data?.speakers.map((items, ind) => {

                                                            return (
                                                                <div
                                                                    className={`${((readMoreProfile == items?.speakerUserId) && showLess == true) ? "readMorewill-smith" : "will-smith mb-3"}`}
                                                                >
                                                                    <div className={`${((readMoreProfile == items?.speakerUserId) && showLess == true) ? "d-block" : ""}`}>
                                                                        <div className={`${((readMoreProfile == items?.speakerUserId) && showLess == true) ? "readMorewill-imgDiv" : "img-div"}`}>
                                                                            <img
                                                                                src={
                                                                                    items?.speakerProfileUrl
                                                                                        !== null &&
                                                                                        items?.speakerProfileUrl
                                                                                        !== undefined &&
                                                                                        items?.speakerProfileUrl
                                                                                        !== ""
                                                                                        ? items?.speakerProfileUrl

                                                                                        : "/images/ApeakerNoImage1.png"
                                                                                }
                                                                                alt=""
                                                                                className="img-fluid sample"
                                                                            />
                                                                        </div>
                                                                        <h3>{items?.fName}</h3>
                                                                    </div>
                                                                    <Col className="mb-4">
                                                                        <p className={showLess == true ? "ms-2 mt-" : ""} style={{ maxWidth: "61.2rem", width: "100%" }}>

                                                                            {((readMoreProfile == items?.speakerUserId) && showLess == true) ? items?.speakerProfile : `${filterSpeakerDescriptionLegth(
                                                                                items?.speakerProfile
                                                                            )}...`}
                                                                            {(items?.speakerProfile.length > 100 && showLess !== true) ? (
                                                                                <a onClick={() => showFullProfile(items, ind, seminarObj.speakers)}>Read More</a>
                                                                            ) : ((readMoreProfile == items?.speakerUserId) && showLess == true) ?
                                                                                <a onClick={() => setShowLess(false)} className="fw-bold text-decoration-none text-black"> Show Less</a> :
                                                                                <a onClick={() => showFullProfile(items, ind, data.speakers)}>Read More</a>
                                                                            }
                                                                        </p>
                                                                    </Col>

                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </Col>


                                            </Row>
                                            <Row>
                                                <Col span={24} >
                                                    <Title level={3} style={{ marginTop: 10, color: "maroon", marginBottom: 0 }}>
                                                        {data?.subtenantId == 745 ? "Workshop" : "About Seminar"}
                                                    </Title>
                                                </Col>
                                                <Col span={24}>
                                                    <Text level={1} className="fs-6">
                                                        {data?.description}
                                                    </Text>
                                                </Col>
                                            </Row>
                                            <div className='d-flex justify-content-center my-4'>
                                                <button style={{ backgroundColor: "#720c20", border: "none" }} className="text-light px-5 fs-8 fs-sm-4 py-2" onClick={() => registerNow(data)}>
                                                    Register Now
                                                </button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <Row>
                                                <Col span={4} xs={24} sm={24} md={4} lg={4} xl={3} className="m-0 mb-2 me-sm-3">
                                                    <div className='eventListingImage'>
                                                        <img src='/icons/rajivSpeakerImage.jpeg' />
                                                    </div>
                                                </Col>
                                                <Col xs={24} md={19} className="p-0 m-0 me-1">
                                                    <Text level={2} className="fs-6">
                                                        70% of Americans not being able to live out their lives at home should be as much of an outrage as racism, gender discrimination, child abuse, etc.  BUT IT IS NOT.<br />
                                                        Seniors are not cute and cuddly.<br />
                                                        This, as you know, has been my life’s MISSION. To change the way we think of, plan for, and navigate through retirement.  And I need your support and help to tear down the walls that keep sunlight out of lives of the retired community members.<br />
                                                        I am inviting a handful of my over 7,000 clients to share what I have been able to accomplish, and what I feel is needed as the next step to finally break down the walls.  And ask for your time and help that will also allow you to leave a sound legacy to be proud of – changing lives of retirees.<br />
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </>
                                }

                            </div>
                            
           
                        </div>
                        </div>
                        {
                modelShow && <ModalComponent modelShow={modelShow} showModal={showModal}>
                    <EnquiryRegistration buttonId={buttonId} seminarObj={data} callAPiFromPopup={props.callAPiFromPopup} />
                </ModalComponent>
            }
     
            {
                data?.subtenantId !== 26 &&
                <SeminarListingFooter />
            }

                      
                </>
                )

            })}
        </>

       
    )
}

export default Readmore
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../App";
import commonLib from "../../control/commonLib";
import konsole from "../../network/konsole";
import Services from "../../network/Services";
import "./Header.css";

const Header = (props) => {
  let { subtenantLogo } = useContext(UserContext);
  const seminarObj = commonLib.getObjFromStorage('seminarObj');
  useEffect(() => {
  }, [seminarObj])

  return (
    <div className="container-fluid bg-white headerHeight">
      <div className="d-flex justify-content-between align-items-center " style={{height:'auto'}}>
           <div className="">
            {
              subtenantLogo !== '' ? <img src={subtenantLogo} className='subtenantLogo  '  alt="Login user image" /> : <h2>SUBTENANT LOGO</h2>
            }
          </div>
          {/* <div className="d-flex align-items-center gap-2">
            <span className="d-flex justify-content-end"> Hi Admin </span>
            <span className={`${props.pageName == "hide" ? "d-none" : ""}`} >
              <img src="/web.png" />
            </span>
          </div> */}
      </div>
    </div>


  );
};

export default Header;

import React, { useContext, useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { UserContext } from '../App';
import commonLib from '../control/commonLib';
import konsole from '../network/konsole';
import Services from '../network/Services';
import Toaster from '../Toaster/Toaster';
import LayoutComponent from './LayoutComponent';
import './Main.css';
import { Space } from 'antd';
import occurrenceId from '../control/occurrence';
import Payment from './SelfPlanner/VenuePayment/Payment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { isNotValidNullUndefinedfile } from '../control/Constant';


export default function AfterPaymentScreen(props) {
  const { setLoader } = useContext(UserContext);
  const [noSlots, setNoSlots] = useState(false);
  const history = useHistory();
  const [showPayment, setshowPayment] = useState(false)
  const planuser = commonLib.getObjFromStorage('plan');
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
  const attendeeObj1 = commonLib.getObjFromStorage("seminarObj");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const stateObj = commonLib.getObjFromStorage('stateObj');
  const venueObj2 = commonLib.getObjFromStorage('venueObj2');
  const [plannerBooking, setPlannerBooking] = useState([]);
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [plannerCalId, setPlannerCalId] = useState(null)
  const [hoveredDate, setHoveredDate] = useState(null);
  const [planningId, setPlanningId] = useState()
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [palnnerState, setPalnnerState] = useState()
  const templeteId1 = useRef(false);

  useEffect(() => {
    plannerCalendr();
    Services.getPlannerPlan().then((resposne)=>{
      if(resposne){
        setPalnnerState(resposne?.data?.data)
       }
     })
  }, [])


  

  const plannerCalendr = () => {
    setLoader(true);
      Services.getPlannerCalenderPath(attendeeObj1?.seminarSubtenantId, true, 1, "UPCOMING").then((res) => {
        setLoader(false);
        if(isNotValidNullUndefinedfile(res?.data?.data)){
          setPlannerBooking(res.data.data)
        }else{
          setNoSlots(true)
        }
      })
      .catch((error) => {
        setLoader(false);
        konsole.log("error", error)
      })
      .finally(error => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  const selectedTimeSlotObj = (index) => {
    if(selectedTimeSlotIndex == index){
      setSelectedTimeSlotIndex(null);
      setSelectedTimeSlot(null)
      commonLib.setDatatoSession("selectedTimeSlot", JSON.stringify(''))
    }else{
      setSelectedTimeSlotIndex(index);
    const selectedTimeSlot = plannerBooking[index];
    setSelectedTimeSlot(selectedTimeSlot);
    setPlannerCalId(selectedTimeSlot.plannerCalId);
    commonLib.setDatatoSession("selectedTimeSlot", JSON.stringify(selectedTimeSlot))
    }
   
  }

  
 
  commonLib.setDatatoSession("plannerCalId", JSON.stringify(plannerCalId))


  
  const postBookSumbit = () => {

    if (selectedTimeSlotIndex === null) {
      Toaster.warning("Please Choose Booking Slot.");
      return;
    }else if(!isNotValidNullUndefinedfile(planningId)){
      Toaster.warning("Please select planning option.");
      return;
    }
    else {
      commonLib.setDatatoSession('isNotSlots',JSON.stringify(false))
      setshowPayment(true)
    }
  }

 
  const GetEmailTemplateUsers = ()=>{
    if(!isNotValidNullUndefinedfile(planningId)){
      Toaster.warning("Please select planning option.");
    }else{
      commonLib.setDatatoSession('isNotSlots',JSON.stringify(true))
      setshowPayment(true)
    }
  
 

     


  }

 
  const findWeekDayName = (date) =>{
    const d = new Date(date);
    let day = d.getDay()

switch (day) {
  case 0:
   return day = "Sun";
    break;
  case 1:
    return day = "Mon";
    break;
  case 2:
    return day = "Tue";
    break;
  case 3:
    return day = "Wed";
    break;
  case 4:
    return day = "Thus";
    break;
  case 5:
    return day = "Fri";
    break;
  case  6:
    return day = "Sat";
}

  }
  let dates = plannerBooking.map((ele) => {
    return new Date(ele?.plannerDate)
  })
  const isDateDisabled = (date) => {

    const dateString = date.toISOString().split('T')[0];
    return !dates.some((disabledDate) => {
      const disabledDateString = disabledDate.toISOString().split('T')[0];
      return disabledDateString === dateString;
    });
  };
  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const isHovered = hoveredDate && date.getTime() === hoveredDate.getTime();
      const classNames = `calendar-tile ${isHovered ? 'hovered' : ''}`;

      return (
        <div
          key={date.getTime()}
          className={classNames}
          onMouseEnter={(event) => handleDateHover(date, { top: event.clientY, left: event.clientX })}
          onMouseLeave={() => stateUpdate()}
        >
          {date.getDate()}
          {isHovered && renderDropdown(date)}
          {/* {renderDropdown(date)} */}
        </div>
      );
    }
  };
  const handleDateHover = (date, mouseCoordinates) => {
    if (!isDateDisabled(date)) {
      setHoveredDate(date);
      const calendarContainer = document.querySelector('.react-calendar');
      const { top: containerTop, left: containerLeft } = calendarContainer.getBoundingClientRect();
      const { top: mouseTop, left: mouseLeft } = mouseCoordinates;

      const dropdownTop = mouseTop - containerTop + calendarContainer.scrollTop + 30; // Adjust the value (30) to set the desired distance from the hovered date

      setDropdownPosition({
        top: dropdownTop,
        left: mouseLeft 
      });

    }
  };
 const handelSelect=(ele,index)=>{
  setSelectedTimeSlotIndex(ele?.plannerCalId);
  const selectedTimeSlot = ele[index];
  
  setSelectedTimeSlot(ele);
  setPlannerCalId(ele.plannerCalId)
 }
  const renderDropdown = (date) => {
    let newjson = plannerBooking.filter((ele) => {

      return new Date(ele?.plannerDate).getTime() == date.getTime()

    })
    return (
      <div className="newDropdown" style={{top: dropdownPosition.top, left: dropdownPosition.left}}>
        {/* <ul className='p-2'style={{maxHeight:"150px",overflowX:"auto"}}> */}
        <ul className='p-2 ulData vertical-scroll'style={{maxHeight:"150px",overflowX:"auto"}}>
          {newjson.map((ele,index) => {
            return (
              <>
                <li className={`gray-border rounded mb-1  ${(selectedTimeSlotIndex === ele?.plannerCalId) ? 'seletectedIndexBackground px-0 p-2 vertical-scroll' : 'border border-dark p-2 vertical-scroll'}p-2 w-100 text-center  cursor-pointer`}
                 onClick={()=>handelSelect(ele,index)}>{ele?.plannerTime} To {convertToSimpleTime((newTime(ele?.plannerTime,ele?.timeDuration)))}</li>
              </>
            )
          })}

        </ul>
      </div>

    );
  };

  const stateUpdate =()=>{
    setHoveredDate(null)
  }
  const newTime = (time, duration) => {
    konsole.log("samofugour87or09",time, duration)
    var timeArray = time.split(' ');
    var timeString = timeArray[0];
    var period = timeArray[1];
  
    var [hours, minutes] = timeString.split(':').map(Number);
    minutes += Number(duration); // Convert duration to a number
  
    hours += Math.floor(minutes / 60); // Add excess minutes to hours
    minutes = minutes % 60; // Get remaining minutes after converting to hours
  
    if (hours >= 12) {
      period = period === 'AM' ? 'PM' : 'AM';
      if (hours > 12) {
        hours -= 12;
      }
    }
    if (hours === 0) {
      hours = 12;
    }
  
    var updatedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
    return updatedTime;
  }
  const convertToSimpleTime=(militaryTime)=> {
    
    let [hours, minutes, period] = militaryTime.split(/:|\s/);
  
   
    hours = parseInt(hours, 10);
    if (period === "PM" && hours !== 12) {
      hours += 12;
    }
    if (hours === 12 && period === "AM") {
      hours = 0;
    }
    hours = String(hours % 12 || 12);
    const simpleTime = hours + ":" + minutes + " " + period;
  
    return simpleTime;
  }
  const showPlanningDetails = () => {
    // Inline styles defined in a JavaScript object
    const containerStyle = {
      textAlign: 'justify'
    };
  
    const headingStyle = {
      textAlign: 'justify'
    };
    return (
      <div className='container border mt-4 mb-2 p-4 estimatePlaning'>
        <h4 style={headingStyle}>
          Please estimate which planning option fits your needs more closely ?*
        </h4>
        {isNotValidNullUndefinedfile(palnnerState) && palnnerState.map((ele) => (
          <React.Fragment key={ele.planningId}>
            <div className={`d-flex ${ele.planningId == planningId ? 'selected-border cursor-pointer' : 'notSelected cursor-pointer'}`} onClick={(e) => setPlanningId(ele.planningId)}>
              <input
                className='me-2 mb-auto mt-2'
                id={ele.planningId}
                checked={ele.planningId == planningId}
                // disabled={!isNotValidNullUndefinedfile(selectedTimeSlot)}
                type='checkbox'
                name={ele?.planningName}
                onChange={(e) => handelClick(e)}
              />
              <div style={containerStyle}>
                <h5>{ele.planningName}</h5>
                <p>{ele.planningDesc}</p>
              </div>
            </div>

          </React.Fragment>
        ))}
        {isNotValidNullUndefinedfile(planningId) && <h6 style={headingStyle}>Note: You can discuss this selection during the initial Consultation.</h6>}

      </div>
    );
  };
  const handelClick=(e)=>{
    let planningData = {
      "planningId" : e.target?.id,
      "planningName" :e.target?.name
    }
    setPlanningId(e.target?.id)
      commonLib.setDatatoSession("planningData", JSON.stringify(planningData));      
  }



  return (
    <div>

      <LayoutComponent>
        <div>

          {/* {(paymentSuccesStatus == "SUCCESS") ? */}
            <div className='container-fluid confirmationBooking-main '>
              <div className='row'>
                <div className='col-sm-6 '>
                  <p className=' ms-2 fs-4 fw-700'>Appointment Booking : <span className='fw-bold'>Book an Appointment</span> </p>
                 
                </div>
              </div>
              <div className='container after-payment-container bg-white text-center'>
              {noSlots == true
              ? (
                <>
                  <h5 className='mt-1 fw-800 fs-4 mt-2'>Sorry for the inconvenience.</h5>
                  <h5 className='mt-1 fw-800 fs-4'>There are no Appointment  slots available currently, please click on "No preferred time slots available"
                    so that one of our team members will contact you as soon as possible. </h5><br /><br /><br />
                       {showPlanningDetails()}
                    </>
              ):<>
                  <div className='row ms-2 ms-sm-0 gap-3 fw-600 align-items-center justify-content-center w-100'>
                  <h1 className='congratulationNew text-center mt-3'>Choose date and time slot to book an Appointment</h1>
                  {
                    plannerBooking.map((item, index) => {
                    const { formattedDate, dayAndYear } = commonLib?.getFormattedDateNew(item.plannerDate);
                      return (
                        <>
                          <div key={index} onClick={() => selectedTimeSlotObj(index)}
                            className={`gray-border rounded ${(selectedTimeSlotIndex === index) ? 'seletectedIndexBackground ' : ''}p-2 w-100 text-center  cursor-pointer`} style={{ maxWidth: '200px' }}
                          >
                              {formattedDate}<br/>{dayAndYear}<br/> {item.plannerTime}
                          </div>
                        </>
                      )
                    })
                  }
                  {/* <div className="my-calendar mt-0 position-relative ">
                        <Calendar className="calendar" tileDisabled={({ date }) => isDateDisabled(date)} tileContent={tileContent} />

                      </div> */}
                </div>
                {showPlanningDetails()}
                  <div className='btm d-flex justify-content-center mt-3 pb-1'>

                    {selectedTimeSlot != null && selectedTimeSlot != undefined && selectedTimeSlot != "" ? <div className='text-center TimeSlot p-2 me-2'>
                      {selectedTimeSlot.plannerTime} To {newTime(selectedTimeSlot?.plannerTime, selectedTimeSlot?.timeDuration)}

                    </div> : ""}
                    <button type='text' onClick={postBookSumbit} className='cnt-bttn border-0 rounded text-center p-2 bookbttn '>
                      BOOK NOW
                    </button>
                  </div>
              </>}



             
              
                <div className='btm text-center mt-2 pb-4'>
                  <h5>No preferred time slots available? Please <Space></Space>
                   <a className='fs-5 text-decoration-underline' onClick={GetEmailTemplateUsers} >
                      click here
                  </a></h5>
                </div>

              </div>
            </div>
         
        </div>
      </LayoutComponent>
      {showPayment ? (
          <Payment
            selectedUser={planuser}
            attendeeObj={attendeeObj}
            seminarObj={seminarObj}
          />
        ) : (
          ""
        )}

    </div>

  )
}

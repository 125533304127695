import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import LayoutComponent from '../../LayoutComponent';
import '../classroomPayment/classpayment.css'
import Commonregcomponent from '../commonComponent/Commonregcomponent';
import commonLib from '../../../control/commonLib';
import konsole from '../../../network/konsole';
import Payment from '../../SelfPlanner/VenuePayment/Payment';
import { useHistory } from 'react-router-dom'

const Classroomvenupayment = (props) => {
  const history = useHistory()
  konsole.log("classRoomDataclassRoomData",props)

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('')
  const [showPayment,setshowPayment]=useState(false)
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");

  const classNewUserData = JSON.parse(commonLib.getSessionStoarge('newRegisterUserData'))
  const newRegisterUserDat = JSON.parse(commonLib.getSessionStoarge('newRegisterUserDat'))
  const state = (props.location.state !== undefined) ? props.location.state : ""
  konsole.log("state",state)

  

    const handlePaymentMethod = (selectedPaymentMethod,method) => {
      konsole.log("selectedPaymentmethodcls",selectedPaymentMethod, method);
      setSelectedPaymentMethod(selectedPaymentMethod);
      setPaymentMethod(method)
    }

    const handlePostPayment=()=>{
    
      if (paymentMethod == '' || paymentMethod == null) {
        alert('Please select  payment method')
        return;
      } else {
      setshowPayment(true)
      setTimeout(()=>{
        setshowPayment(false)
      }, 2000)
      }
    }
 
    const back =()=>{
      history.push({
        pathname: '/Classroomreg', 
        state:  state.useData, 
    })
    }

  return (
    <div>
      <LayoutComponent>

        <Row className='Main-Div-ClassPayment classroomvanupayment-main'>
          <Col span={24} className="ps-4 pt-3">
            <Commonregcomponent text="Payment" topic="Enjoy your retired life" />
            {/* <div className='d-flex'>
              <h5 className='ClassReg-Tag-h4'>Classroom Registration:</h5>&nbsp;<h5>Payment</h5>
            </div>
            <div className='d-flex'>
              <h6 className='ClassReg-Tag-h4'>Topic : &nbsp;</h6><h6 className='ClassReg-Tag-h4'>Enjoy your retired life</h6>
            </div> */}
            <Row className='pe-4'>
              <Col span={24} className='bg-white'>
                <Row className='mt-2  Back-Button-Row'>
                  <Col span={24}>
                    <Link to="Classroomreg" style={{ color: "black",fontSize:"15px" }} >{"<"} All Workshop</Link>
                  </Col>
                </Row>

                <Row>
                  <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Row className='userDetail-Row'>
                      <Col span={24} className="pt-2 ps-2">
                        <div>
                          <h4>User Details</h4>
                        </div>
                        <div>
                          <h6 className='userName-tag-h6' >First name: <span className='userName-tag-span' >{newRegisterUserDat === true ? classNewUserData?.useData?.attendeeFullName : attendeeObj?.attendeeFullName}</span></h6>
                        </div>
                        <div>
                          <h6 className='userName-tag-h6'>Email: <span className='userName-tag-span'>{newRegisterUserDat === true ? classNewUserData?.useData?.attendeeEmailAddress : attendeeObj?.attendeeEmailAddress}</span></h6>
                        </div>
                        <div>
                          <h6 className='userName-tag-h6'>Cell Number: <span className='userName-tag-span'>{newRegisterUserDat === true ? classNewUserData?.useData?.attendeeMobileNo : attendeeObj?.attendeeMobileNo}</span></h6>
                        </div>
                      </Col>
                    </Row>
                    <Row className='custmerDetail-Row'>
                      <Col span={24} className="pt-1 ps-2">
                        <div>
                          <h4>Workshop Details</h4>
                        </div>
                        <div>
                          <h6 className='classRoomName-tag-h6'>Workshop name: <span className='classRoomName-tag-span' >{newRegisterUserDat === true ? classNewUserData?.classRoomData?.seminarTopic : state.classRoomData?.seminarTopic}</span></h6>
                        </div>
                        <div>
                          <h6 className='classRoomName-tag-h6'>Selected Date: <span className='classRoomName-tag-span'>{commonLib.getFormattedDate(newRegisterUserDat === true ?classNewUserData?.classRoomData?.seminarDate: state.classRoomData?.seminarDate)}</span></h6>
                        </div>
                        <div>
                          <h6 className='classRoomName-tag-h6'>Selected Time: <span className='classRoomName-tag-span'>{commonLib.getFormattedTime(newRegisterUserDat === true ?classNewUserData?.classRoomData?.seminarTime:state.classRoomData?.seminarTime)}</span></h6>
                        </div>
                        <div>
                          <h6 className='classRoomName-tag-h6'>Fee: <span className='classRoomName-tag-span'>${state.classRoomData?.seminarFee}</span></h6>
                        </div>
                        <div className='pt-4 pb-3 Back-Button-Div'>
                          {/* <Link to="Classroomreg "> */}
                            <button type="button" class="btn outline d-flex justify-content-center align-items-center Back-Button-Payment"onClick={()=>back()} >
                              Back
                            </button>
                          {/* </Link> */}
                        </div>

                      </Col>
                    </Row>

                  </Col>
                  <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16} className="pt-3 ps-2">
                    <Row>
                      <Col span={24}>
                        <div>
                          <h4>Choose a Payment Method</h4>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={16} xs={24} md={16} lg={16} xl={16} className="ps-1 Payment-Card-Col">
                        <div className='row Payment-Card-Col'>
                          <div className="col-10 col-md-4 p-2 cursor-pointer" onClick={() => handlePaymentMethod(1,"Card")} style={{width:"166px"}} >
                            <div class="card Card-Div"  >
                            <div class={`${(selectedPaymentMethod === 1) ? "seletectedIndexBackground" : ''} card-body text-center rounded`}>
                      <h5 class="card-title"><img src={`${(selectedPaymentMethod === 1) ? '/icons/creditActiveIcon.svg' : '/icons/creditcardPayment.svg'}`} /></h5>
                      <p className='fw-bold'> Credit/Debit Card</p>
                    </div>
                            </div>
                          </div>
                          {/* <div className="col-10 col-md-4 p-2 cursor-pointer" onClick={() => handlePaymentMethod(2, "Paypal")}>
                            <div class="card Card-Div">
                            <div class={`${(selectedPaymentMethod === 2) ? "seletectedIndexBackground" : ''} card-body text-center rounded`}>
                      <h5 class="card-title"><img src={`${(selectedPaymentMethod === 2) ?'/icons/paypalActiveIcon.svg' :'/icons/paypal.svg'}`} /></h5>
                      <p className='fw-bold'>Paypal</p>
                    </div>
                            </div>
                          </div> */}
                          {/* <div className="col-10 col-md-4 p-2 cursor-pointer" onClick={() => handlePaymentMethod(3, "Cash")}>
                            <div class="card Card-Div">
                            <div class={`${(selectedPaymentMethod === 3) ? "seletectedIndexBackground" : ''} card-body text-center rounded`}>
                      <div class="">
                        <h5 class="card-title"><img src={`${(selectedPaymentMethod === 3) ? '/icons/cashActiveIcon.svg' : '/icons/cashPayment.svg'}`} /></h5>
                      </div>
                      <span className='fw-bold'>Cash</span>
                      <p className='vanu-counter'> (For counter booking)</p>
                    </div>
                            </div>
                          </div> */}
                        </div>
                      </Col>


                    </Row>
                    <Row>
                      <Col span={16} xs={24} md={16} lg={16} xl={16} className="d-flex justify-content-center mt-5 MakePayment-Col" >
                        <div>
                          <button type='text' className='px-4 py-1 theme-Btn rounded text-center' style={{ height: "36px" }} onClick={handlePostPayment}>
                            Make Payment
                          </button>
                        </div>
                      </Col>
                    </Row>

                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <>
      {
          showPayment ?   <Payment paymentMethod={paymentMethod} classData = {newRegisterUserDat === true ?classNewUserData:state} attendeeObj={attendeeObj} />:''
        }

      </>

      </LayoutComponent>
    </div>
  )
}

export default Classroomvenupayment
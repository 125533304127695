import React, { useEffect } from 'react'
import { Row, Col, Typography } from "antd";
import commonLib from '../../control/commonLib';
import { withRouter } from 'react-router-dom';
import konsole from '../../network/konsole';

const { Title } = Typography

const CongratsComponent = (props) => {
    konsole.log("props at cong", props);
    const semiarObj = commonLib.getObjFromStorage('seminarObj');
    
    useEffect(() => {
        let urlKey = ''
        const subtenantId = commonLib.getSessionStoarge('subtenantId');
        konsole.log("subtenantId", subtenantId, typeof (subtenantId));
        if (subtenantId !== undefined) {
            urlKey = subtenantId;
        } 
        const redirectUrl = `/eventlisting/${urlKey}`;
        konsole.log("pushed mail",redirectUrl);
        setTimeout(()=>{
            if (props.buttonId == "list"){
                props.callAPiFromPopup();
            }
            else{
                props.history.push(redirectUrl)
            }
        },2000)
    }, [])
    
    return (
        <div>
            <Row>
                <Col span={24}>
                    <Title level={1} className="m-0" style={{ color: "#d79b48",display: "flex", justifyContent: "center",paddingTop:40 }}> Congratulations </Title>
                </Col>
                <Col span={24}>
                    <Title className='' level={3} style={{ color: "#d79b48", display: "flex", justifyContent: "center"}}> Registration Successful</Title>
                </Col>
            </Row>
            <Row justify="center mb-3">
                <Col span={24} className="text-center" >
                    <Title level={5}>Please check your email inbox for a confirmation message.</Title>
                </Col>
            </Row>
        </div>
    )
}

export default withRouter(CongratsComponent)
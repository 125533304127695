import axios from 'axios';
import config from '../config.json'
import konsole from "./konsole";
import AoUrl, { BASE_URL ,LeadStackerBASE_URL} from './url';
import {mapKey,mapApi,LeadStackerToken,LeadStackerToken1} from '../control/Constant'

axios.defaults.timeout = 15000;
axios.defaults.baseURL = BASE_URL;
// axios.defaults.headers.post['Content-Type'] = 'application/json;text/html;charset=u
// axios.defaults.baseURL = config.BASE_URL;

// axios.defaults.headers.post['Content-Type'] = 'application/json;text/html;charset=utf-8';
// axios.defaults.headers.post['Accept'] = 'application/json, text/plain, */*';
// axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;


const invokes = (url, method, body, cancel) => {

    if(cancel){
        cancel();
    }

    try {
        let subtenantId = sessionStorage.getItem("subtenantId");
        let data = JSON.parse(sessionStorage.getItem("seminarObj"))?.seminarSubtenantId
       
        let subtenantIds = subtenantId == null || subtenantId == undefined ? data : subtenantId
        let token = subtenantIds == 3 ? LeadStackerToken1 : subtenantIds == 742 ||  subtenantId == 747 ? LeadStackerToken : ""
       
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        return axios({
            cancelToken:new axios.CancelToken(function executor(c) {
                cancel = c;
            }),
            method:method,
            url:url,
            params:(method==="GET")?body:null,
            data:(method==="POST" || method === "DELETE" || method === "PUT")?body:null
        });
    }catch(error){
        if(axios.isCancel(error)) {
            konsole.error('Request canceled', error.message);
        } else {
            konsole.error('Something went wrong: ', error.message)
        }
    }
};
const invoke = (url, method, body, cancel) => {

    if(cancel){
        cancel();
    }

    try {
        konsole.log('URL: '+url);
        konsole.log('method:'+method);
        konsole.log(((method == "POST")?"body": 'params')+JSON.stringify(body));

        return axios({
            cancelToken:new axios.CancelToken(function executor(c) {
                cancel = c;
            }),
            method:method,
            url:url,
            params:(method==="GET")?body:null,
            data:(method==="POST" || method === "PUT")?body:null
        });
    }catch(error){
        if(axios.isCancel(error)) {
            konsole.error('Request canceled', error.message);
        } else {
            konsole.error('Something went wrong: ', error.message)
        }
    }
};

const Services = {

    initMapScript: () => {
        if (window.google) {
          return Promise.resolve();
        }
        const src = `${mapApi}?key=${mapKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    },
    getContactLead: async (email) => {
        let cancel;
        let url = `${LeadStackerBASE_URL}/${AoUrl.getLeadstackerContacts}?email=${email}`
        let body = {};
        return invokes(url, "GET", body, cancel);
    },
    postContactLead: async (json) => {
        let cancel;
        let url = LeadStackerBASE_URL + "/" +AoUrl.postLeadstackerContacts + json?.id + "/tags/"
        let body = {};
        body['tags'] = json.tags;

        return invokes(url, "POST", body, cancel);
    },
    getPlannerPlan: async () => {
        let cancel;
        let url = AoUrl.getPlannerPlans
        let body = {};
        return invoke(url, "GET", body, cancel);
    },
    deleteContactLead: async (json) => {
        
        let cancel;
        let url = LeadStackerBASE_URL + "/" +AoUrl.postLeadstackerContacts + json?.id + "/tags/"
        let body = {};
        body['tags'] = json.deleteTagss;
        // console.log("json.deleteTagss",body)
        return invokes(url, "DELETE", body, cancel);
    },
    postContactLeadRegist: async (json,tag) => {
        let cancel;
        let url = LeadStackerBASE_URL+"/"+AoUrl.postLeadstackerContacts
        let body = {};
        body['email'] = json.emailAddress;
        body['phone'] = json.phoneNumber;
        body['firstName'] = json.firstName;
        body['lastName'] = json.lastName;
        body['tags'] = tag;

        return invokes(url, "POST", body, cancel);
    },
    getIpAddress: async () => {
        let data = await fetch(AoUrl.getIpAddressUrlPath);
        return await data.json();
    },

    getIpAddress: async (seminarAttendeeId) => {
        konsole.log("IP Address");
        let data = await invoke(AoUrl.getIpAddressUrlPath);
        konsole.log("IP Address data",data);
        // konsole.log("IP Address data1",data);
        return await data.json();
    },
    getCountryCodeList: async () => {
        let cancel;
        let body = {};
        let url = AoUrl.getCountryCodeListPath
        return invoke(url, "GET", body, cancel);
    },
    getSubtenantDetail: async (subtenantId, isActive) => {
        let cancel;
        let url = AoUrl.getSubtenantDetailPath
        let body = {};
        body['subtenantId'] = subtenantId;
        body['isActive'] = isActive;
        return invoke(url, "POST", body, cancel);
    },
    getSeminarPublisher1: async (seminarId, isActive) => {
        let cancel;
        let url = AoUrl.getSeminarPublishePath;
        let body = {};
        body['seminarId'] = parseInt(seminarId);
        body['isActive'] = isActive;

        return invoke(url, "POST", body, cancel);
    },
    getSeminarPublisherDetail: async (subtenantId,isActive,seminarId,seminarType) => {
        let cancel;
        let url = AoUrl.getSeminarPublishePath
        let body = {};
        body['subtenantId'] = subtenantId;
        if(seminarId !== null){
            body['seminarId'] = seminarId;
        }
        // body['coordinatorUserId'] = coordinatorUserId;
        body['isActive'] = isActive;
        body['seminarType'] = seminarType;

        return invoke(url, "POST", body, cancel);
    },
    getSeminarPublisherDetailID: async (subtenantId,isActive,seminarType,seminarId) => {
        let cancel;
        let url = AoUrl.getSeminarPublishePath
        let body = {};
        body['subtenantId'] = subtenantId;
        if(seminarId !== undefined && seminarId !== null && seminarId !== ""){
            body['seminarId'] = seminarId;
        }
        // body['coordinatorUserId'] = coordinatorUserId;
        body['isActive'] = isActive;
        body['seminarType'] = seminarType;

        return invoke(url, "POST", body, cancel);
    },
    
    registerNewUser: async (subtenantId, seminarId, userRegistration) => {
        let cancel;
        let url = (subtenantId !== 26)?AoUrl.registerWalkInUserPath : AoUrl.registerWalkInUserForInvestPath
        let body = {};
        body['subtenantId'] = subtenantId;
        body['seminarId'] = seminarId;
        body['userRegistration'] = userRegistration;
        

        return invoke(url, "POST", body, cancel);
    },
    getSeminarAttendeeAccessLink: async (uniqueAccessKey, linkAccessIp) =>{
        let cancel;
        let url = AoUrl.getSeminarAttendeeAccessLinkPath
        let body = {};
        
        body['uniqueAccessKey'] = uniqueAccessKey;
        body['linkAccessIp'] = linkAccessIp;

        return invoke(url, "GET", body, cancel);
    },
    upsertSeminarAttendee: async (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, userGuestsNos, leadFrom, upsertedBy, isActive) => {
        let cancel;
        let url = AoUrl.upsertSeminarAttendeePath
        let body = {};
        let attendees = {};
        attendees['seminarAttendeeId'] = parseInt(seminarAttendeeId);
        attendees['attendeeUserId'] = attendeeUserId;
        attendees['semUserStatusId'] = parseInt(semUserStatusId);
        attendees['userGuestsNos'] = parseInt(userGuestsNos);
        attendees['upsertedBy'] = upsertedBy;
        attendees['isActive'] = isActive;
        attendees['leadFrom'] = leadFrom;
        body['seminarId'] = seminarId;
        body['attendees'] = [attendees] || null;

        return invoke(url, "POST", body, cancel);
    },
    updateSeminarAttendeeInfo: async (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy) => {
        let cancel;
        let url = AoUrl.updateSeminarAttendeeInfoPath
        let body = {};
        body['seminarAttendeeId'] = parseInt(seminarAttendeeId);
        body['attendeeUserId'] = attendeeUserId;
        body['semUserStatusId'] = parseInt(semUserStatusId);
        body['upsertedBy'] = upsertedBy;
        body['seminarId'] = seminarId;
        konsole.log("preom", body);
        return invoke(url, "PUT", body, cancel);
    },
    getReferenceMode: async() =>{
        let cancel;
        let url = AoUrl.getReferenceModePath
        let body = {};
        return invoke(url, "GET", body, cancel);
    },

    // {
    //     "plannerCalId": 0,
    //     "plannerUserId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //     "subtenantId": 0,
    //     "isActive": true,
    //     "slotStatusId": 0,
    //     "slotStatus": "string"
    //   }
    SendEmailCom: async (emailType,emailTo,emailSubject,emailTemplateId,emailContent,emailStatusId,emailMappingTable,emailMappingTablePKId,createdBy) => {
        let cancel;
        let url = AoUrl.PostEmailCom
        let body = {};
        body['emailType'] = emailType;
        body['emailTo'] = emailTo;
        body['emailSubject'] = emailSubject;
        body['emailTemplateId'] = emailTemplateId;
        body['emailContent'] = emailContent;
        body['emailStatusId'] = emailStatusId;
        body['emailMappingTable'] = emailMappingTable;
        body['emailMappingTablePKId'] = emailMappingTablePKId;
        body['createdBy'] = createdBy;
     

        return invoke(url, "POST", body, cancel);
    },

    postSendText: async(smsType, textTo, textContent, smsTemplateId, smsStatusId, smsMappingTable, smsMappingTablePKId, createdBy) => {
        let cancel;
        let url = AoUrl.postSendTextPath;
        let body = {}

        body['smsType'] = smsType ;
        body['textTo'] = textTo ;
        body['textContent'] = textContent ;
        body['smsTemplateId'] = smsTemplateId ;
        body['smsStatusId'] = smsStatusId ;
        body['smsMappingTable'] = smsMappingTable ;
        body['smsMappingTablePKId'] = smsMappingTablePKId ;
        body['createdBy'] = createdBy ;

        konsole.log("postSendTextpostSendText",JSON.stringify(body))


        return invoke(url, 'POST', body, cancel)

    },
    getTextTemplateFunc: async (TempalteId,isActive) => {
        let cancel;
        let url = AoUrl.getTextTemplate + `?TextTemplateId=${TempalteId}&IsActive=${isActive}`;

        return invoke(url, "GET", cancel);
    },
    postgetCommMedium: async(occurrenceId, isActive, subtenantId) =>{
        let cancel;
        let url = AoUrl.getCommMediumPath;
        let body = {};

        body['occurrenceId'] = occurrenceId;
        body['isActive'] = isActive;
        body['subtenantId'] = subtenantId;

        konsole.log("getCommMediumPath",JSON.stringify(body))

        return invoke(url, 'POST', body, cancel)
    },
    putUserActivate1: async(seminarAttendeeId)=>{
        let cancel;
        let url = AoUrl.userActivate11 + `?SeminarAttendeeId=${seminarAttendeeId}`

        
        let body = {};
        // body["seminarAttendeeId"] = seminarAttendeeId;
       
       //konsole.log("haat",body)
    return invoke(url, 'PUT', body, cancel)
    },

    getSubtenantSeminarRateCard : async (subtenantRateCardId,isActive) => {
        let cancel;
        let url = AoUrl.getSubtenantRateCardPathSeminar;
        let body ={}
        body['subtenantRateCardId'] = subtenantRateCardId
        // body['skuSubsModelId'] = skuSubsModelId
        // body['rateCardId'] = rateCardId
        // body['subtenantId'] = subtenantId
        // body['skuListId'] = skuListId
        body['isActive'] = isActive
        return invoke(url, "GET", body, cancel);
      },

    GetUserRolebByID: async (userId) => {
        let cancel;
        let url = AoUrl.GetUserRole + `${userId}`;
        let body = {}
    
        // body['subtenantId'] = subtenantId;
       
        konsole.log("body",JSON.stringify(body))
        
        
        
        return invoke(url, "GET", cancel);
    },

    userActivationPost: async (userRegstrtnId,userId,signUpPlatform,createdBy,clientIPAddress) => {
        let cancel;
        let url = AoUrl.userActivation
        let body = {};
        body['userRegstrtnId'] = userRegstrtnId;
        body['userId'] = userId;
        body['signUpPlatform'] = signUpPlatform;
        body['createdBy'] = createdBy;
        body['clientIPAddress'] = clientIPAddress;
        

        return invoke(url, "POST", body, cancel);

        
    },

    GetEmailTemplateUser: async (TempalteId,isActive) => {
        let cancel;
        let url = AoUrl.GetEmailTemplate + `${TempalteId}&IsActive=${isActive}`;
       
        return invoke(url, "GET", cancel);
    },





    getPlannerCalenderPath: async (subtenantId,isActive,slotStatusId,slotStatus) => {
        let cancel;
        let url = AoUrl.getPlannerCalenderPath
        let body = {};
        body['subtenantId'] = subtenantId;
        body['isActive'] = isActive;
        body['slotStatusId'] = slotStatusId;
        body['slotStatus'] = slotStatus;
        
        return invoke(url, "POST", body, cancel);
    },
    postClassRoomRegister: async(userId,seminarAttendeeId,seminarId,classroomId,createdBy)=>{
        let cancel;
        let url = AoUrl.postClassRoomBooking

        
        let body = {};
        body["userId"] = userId;
        body["seminarAttendeeId"] = seminarAttendeeId;
        body["seminarId"] = seminarId;
        body["classroomId"] = classroomId;
        body["createdBy"] = createdBy;
       
       konsole.log("haat",body)
    return invoke(url, 'POST', body, cancel)
    },
    putPaymentClassBookingStatus: async(bookingId,isActive,updatedBy,remarks,paymentTrxnNo,paymentStatusId,bookingStatusId)=>{
        let cancel;
        let url = AoUrl.putPaymentBookingStatus
        let body = {};
        body["bookingId"] = bookingId;
        body["isActive"] = isActive;
        body["updatedBy"] = updatedBy;
        body["remarks"] = remarks;
        body["paymentTrxnNo"] = paymentTrxnNo;
        body["paymentStatusId"] = paymentStatusId;
        body["bookingStatusId"] = bookingStatusId;
       
       konsole.log("haat",body)
    return invoke(url, 'PUT', body, cancel)
    },
    putClassBooking : async(bookingId,isActive,updatedBy,remarks,classroomAttendeeId)=>{
        let cancel;
        let url = AoUrl.putClassRoomBooking
        let body = {};
        body["bookingId"] = bookingId;
        body["isActive"] = isActive;
        body["updatedBy"] = updatedBy;
        body["remarks"] = remarks;
        body["classroomAttendeeId"] = classroomAttendeeId;
       
    //    konsole.log("haat",body)
    return invoke(url, 'PUT', body, cancel)
},

    updatePlannerBooking: async (plannerAppId,seminarAttendeeId,plannerCalId,bookingStatusId,bookingStatusDate,updatedBy) => {
        let cancel;
        let url = AoUrl.getPlannerStatusUpdatePath
        let body = {};
        body['plannerAppId'] = parseInt(plannerAppId);
        body['seminarAttendeeId'] = seminarAttendeeId;
        body['plannerCalId'] = plannerCalId;
        body['bookingStatusId'] = bookingStatusId;
        body['bookingStatusDate'] = bookingStatusDate;
        body['bookingStatusDate'] = bookingStatusDate;
        
        body['updatedBy'] = updatedBy;
     
        konsole.log("body updatedMember", body);
        return invoke(url, "POST", body, cancel);
    }, 


    upsertPlannerBooking: async (plannerAppId, seminarAttendeeId, billTrackNo, paymentStatusId, paymentStatusDate, upsertedBy, isActive,planningId) => {
        let cancel;
        let url = AoUrl.upsertPlannerBookingPath
        let body = [];
        let bookingSlot = {};
        bookingSlot['plannerAppId'] = parseInt(plannerAppId);
        bookingSlot['seminarAttendeeId'] = seminarAttendeeId;
        bookingSlot['upsertedBy'] = upsertedBy;
        bookingSlot['isActive'] = isActive;
        bookingSlot['billTrackNo'] = billTrackNo;
        bookingSlot['paymentStatusId'] = paymentStatusId;
        bookingSlot['paymentStatusDate'] = paymentStatusDate;
        bookingSlot['planningId'] = planningId;

        body = [bookingSlot] ;

        return invoke(url, "POST", body, cancel);
    },

    getSendCheckInLinktoUser : async (SeminarAttendeeId,ClientIPAddress) => {
        let cancel;
        let url = AoUrl.getSendCheckInLinktoUser + `SeminarAttendeeId=${SeminarAttendeeId}&ClientIPAddress=${ClientIPAddress}`
        let body = {};
        body['SeminarAttendeeId'] = SeminarAttendeeId;
        body['ClientIPAddress'] =ClientIPAddress;
        return invoke(url, "POST", body, cancel);
    },



     getDeviceType: (callback) => {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                navigator.userAgent
            )
        ) {
            callback("Mobile");
        }
        else if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
            callback("Tablet")
        }
        else {
            callback("Desktop");
        }
    },
    validateSeminarAttendee: async (subtenantId, seminarId, contact, currentStatus) => {
        let cancel;
        let url = AoUrl.validateSeminarAttendeePath
        let body = {};
        body['subtenantId'] = parseInt(subtenantId);
        body['seminarId'] = parseInt(seminarId);
        body['contact'] = contact;
        body['currentStatus'] = currentStatus;
    //  konsole.log("json211",body)
        return invoke(url, "POST", body, cancel);
    },

    postaddressbyuserid:async(data)=>{
        let cancel;
        let url=AoUrl.postregisteraddress
        return invoke(url,"POST",data,cancel)

    },

    postuserorderAddUserOrder:async(adduserobj)=>{
        let cancel;
        let url=AoUrl.postAddUserOrder;
       

        return invoke(url,"POST",adduserobj,cancel)
    }

}


const loadAsyncScript = async (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  };

export default Services;

import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Button, Typography, Form, Input, Select } from "antd";
import "./enquiryregistration.css"
import CongratsComponent from '../CongratsForm/CongratsComponent';
import ModalComponent from '../Modal/ModalComponent';
import Services from '../../network/Services';
import konsole from '../../network/konsole';
import commonLib from '../../control/commonLib';
import { defaultIPAddress, EnquiryRegistrationId } from '../../control/Constant';
import { UserContext } from '../../App';
import Toaster from '../../Toaster/Toaster';
import InputMask from "../../component/PhoneNumberMask"
const { Title } = Typography;
const { Option } = Select;


const EnquiryRegistration = (props) => {
  const { setLoader } = useContext(UserContext);
  const [form] = Form.useForm();
  const [modelShow, setModalShow] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const seminarObjFromStorage = commonLib.getObjFromStorage('seminarObj');
  // const subtenantId = (seminarObjFromStorage !== null && seminarObjFromStorage !== '')? seminarObjFromStorage.seminarSubtenantId : 2; 
  const seminarObj = props.seminarObj;
  const [clientIpAddress, setClientIpAddress] = useState(defaultIPAddress);
  const [registeredUser, setRegisteredUser] = useState(true);
  const [referenceModeList, setReferenceMode] = useState([]);

  useEffect(() => {
    getIpAddress();
    getCountryCodeList();
    getReferenceMode();
  }, [])

  const getReferenceMode = () => {
    setLoader(true);
    let promise = Services.getReferenceMode();
    promise.then(res => {
      setLoader(false);
      konsole.log("reference mode", res);
      setReferenceMode(res.data.data)
    }).finally(()=>{
      setLoader(false);
    })
  }

  const getIpAddress = () => {
    setLoader(true);
    let promise = Services.getIpAddress();
    promise.then(res => {
      setLoader(false);
      konsole.log("IP address", res);
      setClientIpAddress(res.IPv4);
    })
      .finally(() => {
        setLoader(false);
      })
  }

  const getCountryCodeList = () => {
    setLoader(false);
    const promises = Services.getCountryCodeList();
    promises.then(res => {
      konsole.log('countryCodeList', res);
      setCountryCodeList(res.data.data)
    })
      .catch(err => {
        konsole.log("err", err);
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  const registerNewUser = (subtenantId, seminarId, userRegistration) => {
    setLoader(true);
    const promise = Services.registerNewUser(subtenantId, seminarId, userRegistration)
    promise.then(res => {
      konsole.log('Enquery Register user ', res.data);
      setRegisteredUser(false);
    })
    .catch(err => {
      konsole.log("err", err.response);
      Toaster.warning(err.response.data.messages[0])

    }).finally(fin => {
      konsole.log("finish");
      setLoader(false);
    })
  }

  const onChangePhone = (value) => {
    form.setFieldsValue({
      phoneNumber: value,
    });
  };

  const onSubmit = data => {
    let userRegistration = data;
    userRegistration['roleId'] = 2; 
    userRegistration['noOfAttendee'] = parseInt(userRegistration.noOfAttendee);
    userRegistration['referenceMode'] = parseInt(userRegistration.referenceMode);
    userRegistration['clientIPAddress'] = clientIpAddress;
    userRegistration['countryCode'] = '+1';
    userRegistration['semUserStatusId'] = EnquiryRegistrationId;

    const noOfRegisteredSeats = seminarObj.noOfRegisteredSeats;
    const noOfSeats = seminarObj.noOfSeats;
    if(userRegistration.noOfAttendee > (noOfSeats - noOfRegisteredSeats)){
      Toaster.info(`Only ${noOfSeats - noOfRegisteredSeats} Seats remaining`);
      return;
    }
    registerNewUser(seminarObj.subtenantId, seminarObj.seminarId, userRegistration);
  }

  return (
    <div>
          <Row justify='center'>
          <Col span={8} style={{ display: "flex", justifyContent: "center" }}>
            {
              props.seminarObj.subtenantId !== 26 ?
              <img src={seminarObjFromStorage?.logoUrl} className='Modal-Logo-Image' alt='subtenant logo'/> : 
                  <img src='/aoLogo.png' className='mt-4' alt='subtenant logo' />
            }
          </Col>
        </Row>
      {(registeredUser == true)?
      <>
        <div style={{ padding: 10 }}>
          <Row >
            <Col>
              <Row >
                <Col span={24} xs={18} sm={18} md={24} lg={24} xl={24}>
                    <Title level={3} className="Modal-Retire-Life-Tag" style={{ color: "#B06A0A" }}>{seminarObj.seminarTopic}  {
                      props.seminarObj.subtenantId !== 26 &&
                      <span classname="ms-2 " style={{ color: "black", fontSize: "15px" }}>({seminarObj.seminarType}, {props.seminarObj.isSeminarPaid == true ? 'Paid' : 'Free'}{props.seminarObj.isSeminarPaid == true ? " : " + "$" + props.seminarObj.seminarFee : ""})</span>
                    }
                    </Title>
                </Col>
              </Row>

              <Row >
                <Col span={24}>
                  <Title level={5}>By: 
                      {
                        seminarObj.speakers.length > 0 && seminarObj.speakers.map((speaker, index) => {
                          const fName = (speaker.fName !== null && speaker.fName !== '') ? speaker.fName : '';
                          const mName = (speaker.mName !== null && speaker.mName !== '') ? speaker.mName : ' ';
                          const lName = (speaker.lName !== null && speaker.fName !== '') ? speaker.lName : '';
                          return <span style={{ color: "maroon", fontSize: "14px" }}>
                            {` ${fName + " " + mName + " " + lName} ${(seminarObj.speakers.length - 1 === index) ? '' : ', '}`}
                            </span>
                        })
                      }
                  </Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                    <Title level={5} style={{ color: "rgb(85, 82, 82)", fontSize: "14px" }}>On {commonLib.getFormattedDate(props.seminarObj.seminarDate)}, at {commonLib.getFormattedTime(props.seminarObj.seminarTime)}</Title>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Title level={5} style={{ color: "rgb(85, 82, 82)", fontSize: "14px" }}>Location : {seminarObj.seminarVenue}</Title>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>

              <Form form={form} onFinish={onSubmit}>

                <Row justify="space-between" style={{ marginBottom: "0px" }}>

                  <Col span={12}>
                    <Form.Item style={{ marginBottom: 10, marginRight: 5 }} name="firstName"
                      rules={[
                        {
                          required: "true",
                          pattern: new RegExp(/^[a-zA-Z@~:\\/?>.<,-]+$/i),
                          message: "Please enter First Name"
                        },
                      ]}
                    >

                      <Input  placeholder="First Name" />

                    </Form.Item >
                    <Row >
                      {/* <Col xs={12}>
                        <Form.Item style={{ marginBottom: 10, marginRight: 5 }} name="countryCode"
                          rules={[
                            {
                              required: "true",
                              message: "Country Code is required"
                            },
                          ]}>
                          <Select placeholder="Country Code">
                            {
                              countryCodeList.map((code, index) => {
                                return <Option value={code.value}>{code.label}</Option>
                              })
                            }
                          </Select>
                        </Form.Item >
                      </Col> */}
                      <Col xs={24}>
                        <Form.Item style={{ marginBottom: 10, marginRight: 5 }} name="phoneNumber"
                          rules={[
                            {
                              required: "true",
                              message: "Please enter Phone Number"
                            },{
                              pattern: new RegExp(/^([+]\d{2})?\d{10}$/),
                              message: "Please enter valid Phone Number"

                            }
                          ]}  >
                         
                          <InputMask onValueChange={onChangePhone} />
                        </Form.Item >
                      </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 10, marginRight: 5 }} name="noOfAttendee"
                      rules={[
                        {
                          required: "true",
                          pattern: new RegExp(/^[0-9]+$/i),
                          message: "please enter only digits"
                        },
                      ]}>
                      <Input  placeholder="Number of Attendees" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>

                    <Form.Item style={{ marginBottom: 10, marginLeft: 5 }}
                      name="lastName"
                      rules={[
                        {
                          required: "true",
                          pattern: new RegExp(/^[a-zA-Z@~:\\/?>.<,-]+$/i),
                          message: "Please enter the Last Name"
                        },
                      ]}  >
                      <Input   placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 10, marginLeft: 5 }}
                      name="emailAddress" rules={[{ type: 'email', message: 'The input is not valid E-mail!', }, { required: "true", message: 'Please input your E-mail!', }]}>
                      <Input  placeholder="Email Address" />
                    </Form.Item>
                    {/* <Form.Item style={{ marginBottom: 10, marginLeft: 5 }} name="leadFrom">
                      <Input  placeholder="Lead From" />
                    </Form.Item> */}
                    {
                      props.seminarObj?.subtenantId !== 26 && 
                      <Form.Item style={{ marginBottom: "20px" }} name='referenceMode'>
                        <Select placeholder="How did you hear about us?">
                          {
                            referenceModeList.length > 0 && referenceModeList.map((reference, index) => {
                              return <Option value={reference.value}>{reference.label}</Option>
                            })
                          }
                        </Select>
                      </Form.Item>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                      <Row className='d-none'>
                      <Col span={24}>
                        <Title level={5} style={{ color: "maroon" }}>How did you hear about us?</Title>
                        {/* <Form.Item style={{ marginBottom: "20px" }} name='referenceMode'>
                          <Select placeholder="How did you hear about us?">
                              {
                                referenceModeList.length > 0 && referenceModeList.map((reference, index) => {
                                  return <Option value={reference.value}>{reference.label}</Option>
                                })
                              }
                          </Select>
                        </Form.Item> */}
                      </Col>
                    </Row>
                    <Row justify="center" style={{ marginBottom: "0px" }}>
                      <Col>
                        <Button style={{ backgroundColor: "maroon", color: "white" }} htmlType="Submit">Register Now</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </>
      :
        <CongratsComponent buttonId={props.buttonId}  callAPiFromPopup={props.callAPiFromPopup}/>
      }
    </div>
  )
}

export default EnquiryRegistration
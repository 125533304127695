// import React, { useCallback, useEffect, useState} from 'react'
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Typography, Button } from "antd";
import Readmore from '../Enquiry/ReadMore/Readmore';
import Searchcomponent from './SearcComponent/Searchcomponent';
import ModalComponent from "../Modal/ModalComponent";
import EnquiryRegistration from '../Enquiry/EnquiryRegistration';
import konsole from "../../network/konsole";
import Services from '../../network/Services';
import "./Eventlisting.css";
import { Link, useParams, useHistory } from "react-router-dom";
import commonLib from "../../control/commonLib";
import SeminarListingHeader from "../SeminarListingFooter";
import SeminarListingFooter from "../SeminarListingFooter";
import EventHeader from "../SeminarListingHeader";
import EventMenu from './EventMenu'
import { UserContext } from "../../App";
import Registration from "../selfchecking/RegisterNow/Registration";
import Modal from 'react-bootstrap/Modal';
import QRCode from "react-qr-code";
import occurrenceId from "../../control/occurrence";
import { EventBaseUrl } from "../../network/url";
import { supportEmail } from "../../control/Constant";


const { Title } = Typography;

const Eventlisting = (props) => {
    const history = useHistory();
    const { setLoader, setSubtenantLogo } = useContext(UserContext);
    const topContainerRef = useRef(null);
    const [isMounted, setIsMounted] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [modelShow, setModalShow] = useState(false);
    const [readMoreShow, setReadMoreShow] = useState(false);
    const [seminarList, setSeminarList] = useState([]);
    const [publisher, setPublisher] = useState([]);
    const [isValid, setISValid] = useState(false);
    const [seminarObj, setSeminarObj] = useState({});
    const [filterUSingChecbox, setFilteredUsingCheckobx] = useState('');
    const [search, setSearch] = useState('');
    const buttonId = useRef("list");
    const [checkfree, setChecfree] = useState("");
    const [checkpaid, setCheckpaid] = useState("")
    const [checkdirect, setcheckdirect] = useState('');
    const [checkwebinar, setcheckwebinar] = useState('')

    const [imageUrl, setImageUrl] = useState('');
    const [text, setText] = useState('');
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const subtenantIdKay = commonLib.getObjFromStorage("subtenantId");
    // const subtenantIdKay = 2



    const [isPaid, setIsPaid] = useState(null);
    // const [isFreeState, setFreeState] = useState(null);
    // const [isPaidState, setPaidState] = useState(null);
    // const [isStateInPerson, setStateInPerson] = useState(null)
    // const [isStateWebinar, setStateWebinar] = useState(null)
    const [directwebinar, setdirectwebinar] = useState('');


    konsole.log("isPaidddddddd", isPaid, directwebinar, buttonId);
    // konsole.log("isFreeState", isFreeState);
    // konsole.log("isPaidState", isPaidState);


    konsole.log("setcheckdirect", checkfree, checkpaid);


    const subtenantId = commonLib.getSessionStoarge('subtenantId') || 2;


    const { subtenantKey } = useParams();

    konsole.log("ttt", seminarList)
    konsole.log("search", search)
    useEffect(() => {
        if (isMounted) {
            konsole.log("subtenantKey", subtenantKey);
            commonLib.setSessionStoarge("subtenantId", subtenantKey);
            const isActive = true //for active subtenantId
            getSeminarPublish(subtenantKey,true,"UPCOMING");
            getSubtenantDetail(subtenantKey, isActive);
        }
        return () => {
            setIsMounted(false);
        }
    }, [])

    const generateQrCode = async () => {
        try {
            const response = await QRCode.toDataURL(text);
            setImageUrl(response);

        } catch (error) {
            konsole.log(error);
        }
    }

    // konsole.log("qrulr",seminarO)

    // const checkboxdirect = (e) => {
    //     konsole.log("eeee", e.target.checked)
    //     if (e.target.checked == true) {
    //         setcheckwebinar('')
    //         setcheckdirect(46);

    //     }
    //     else {
    //         setcheckdirect('');
    //     }
    // }
    // const checkboxwebinar = (e) => {
    //     konsole.log("eeee", e.target.checked)
    //     if (e.target.checked == true) {
    //         setcheckdirect('');
    //         setcheckwebinar(47);

    //     }
    //     else {
    //         setcheckwebinar('');
    //     }
    // }

    // const checkboxfree = (e) => {
    //     konsole.log("checkboxfree", e.target.checked)

    //     if (e.target.checked == true) {
    //         setCheckpaid('');
    //         setChecfree(false);

    //     }
    //     else {
    //         setChecfree('');
    //     }
    // }
    // const checkboxpaid = (e) => {
    //     konsole.log("checkboxfree", e.target.checked)
    //     if (e.target.checked == true) {
    //         setChecfree('')
    //         setCheckpaid(true);

    //     }
    //     else {
    //         setCheckpaid('');
    //     }
    // }

    // } else if (checkpaid != "") {
    //     filteredData = seminarList.filter(items => items.isSeminarPaid == checkpaid)
    // }else if (checkfree != "") {
    //     filteredData = seminarList.filter(items => items.isSeminarPaid == checkfree)
    // }




    konsole.log('filteredData state', isPaid, directwebinar)
    const filteredData = () => {
        let filteredData = [];
        if (directwebinar !== "" && isPaid !== null && search !== "") {
            konsole.log('filteredData', isPaid, directwebinar)
            filteredData = seminarList.filter(items => items.seminarTypeId == directwebinar && items.isSeminarPaid == isPaid && items.seminarTopic.toLowerCase().includes(search.toLocaleLowerCase()))
            filteredData = seminarList.filter(items => items.seminarTypeId == directwebinar && items.isSeminarPaid == isPaid && items.seminarTopic.toLowerCase().includes(search.toLocaleLowerCase()) || (items.speakers[0]?.mName !== null && items.speakers[0]?.mName !==undefined && items.speakers[0]?.mName !==""? items.speakers[0]?.fName+" "+items.speakers[0]?.mName+" "+items.speakers[0]?.lName  : items.speakers[0]?.fName+" "+items.speakers[0]?.lName).toLowerCase().includes(search.toLocaleLowerCase()))
        }
        else if (directwebinar !== "" && isPaid !== null) {
            konsole.log('filteredData', isPaid, directwebinar)
            filteredData = seminarList.filter(items => items.seminarTypeId == directwebinar && items.isSeminarPaid == isPaid)
        }
        else if (directwebinar !== "" && search !== "") {
            konsole.log('filteredData', isPaid, directwebinar)
            filteredData = seminarList.filter(items => items.seminarTypeId == directwebinar && items.seminarTopic.toLowerCase().includes(search.toLocaleLowerCase()))
            filteredData = seminarList.filter(items => items.seminarTypeId == directwebinar && items.seminarTopic.toLowerCase().includes(search.toLocaleLowerCase()) || (items.speakers[0]?.mName !== null && items.speakers[0]?.mName !==undefined && items.speakers[0]?.mName !==""? items.speakers[0]?.fName+" "+items.speakers[0]?.mName+" "+items.speakers[0]?.lName  : items.speakers[0]?.fName+" "+items.speakers[0]?.lName).toLowerCase().includes(search.toLocaleLowerCase()))
        }
        else if (isPaid !== null && search !== "") {
            filteredData = seminarList.filter(items => items.isSeminarPaid == isPaid && items.seminarTopic.toLowerCase().includes(search.toLocaleLowerCase()))
            filteredData = seminarList.filter(items => items.isSeminarPaid == isPaid && items.seminarTopic.toLowerCase().includes(search.toLocaleLowerCase()) || (items.speakers[0]?.mName !== null && items.speakers[0]?.mName !==undefined && items.speakers[0]?.mName !==""? items.speakers[0]?.fName+" "+items.speakers[0]?.mName+" "+items.speakers[0]?.lName  : items.speakers[0]?.fName+" "+items.speakers[0]?.lName).toLowerCase().includes(search.toLocaleLowerCase()))
        }
        else if (isPaid !== null) {
            filteredData = seminarList.filter(items => items.isSeminarPaid == isPaid)
        }
        else if (directwebinar !== "") {
            filteredData = seminarList.filter(items => items.seminarTypeId === directwebinar)
            // konsole.log("aabb",filteredData)
        }
        else if (directwebinar === 47) {
            filteredData = seminarList.filter(items => items.seminarType !== "Direct")
        }

        else if (search !== "") {
            filteredData = seminarList.filter(items => items.seminarTopic.toLowerCase().includes(search.toLocaleLowerCase()))
            filteredData = seminarList.filter(items => items.seminarTopic.toLowerCase().includes(search.toLocaleLowerCase()) || (items.speakers[0]?.mName !== null && items.speakers[0]?.mName !==undefined && items.speakers[0]?.mName !==""? items.speakers[0]?.fName+" "+items.speakers[0]?.mName+" "+items.speakers[0]?.lName  : items.speakers[0]?.fName+" "+items.speakers[0]?.lName).toLowerCase().includes(search.toLocaleLowerCase()))
        }
        else {
            filteredData = seminarList
        }
        // if(isFreeState==true && isPaidState==true)
        // {
        //     filteredData = seminarList
        //     konsole.log("i love you")
        // }
        // else if (isPaid == false && isFreeState ==true) {
        //     filteredData = seminarList.filter(items => items.isSeminarPaid == isPaid)
        // }
        // else if(isPaid == true && isPaidState ==true){
        //     filteredData = seminarList.filter(items => items.isSeminarPaid == isPaid)
        // }
        // else {
        //     filteredData = seminarList
        //     konsole.log("i hate you")
        // }
        return filteredData
    }

    const getSubtenantDetail = (subtenantId, isActive) => {
        setLoader(true);
        const promises = Services.getSubtenantDetail(subtenantId, isActive);
        promises.then(res => {
            setLoader(false);
            konsole.log("subtenant detail1", res.data.data);
            const eventListingSubtenantResponse = res.data.data[0];
            konsole.log("eventListing", eventListingSubtenantResponse);
            const seminarEventObj = commonLib.getEventListSubtenantObj(eventListingSubtenantResponse);
            konsole.log("seminarObj1", seminarEventObj);
            setSubtenantLogo(eventListingSubtenantResponse.subtenantLogoUrl);
            eventListingSubtenantResponse.logoUrl = eventListingSubtenantResponse.subtenantLogoUrl;
            commonLib.setSessionStoarge('seminarObj', JSON.stringify(eventListingSubtenantResponse));
        }).catch(err => {
            konsole.log("error", err.response);
        }).finally(() => {
            konsole.log("fin");
            setLoader(false);
        })
    }



    const callAPiFromPopup = () => {
        getSeminarPublish(subtenantKey);
        // konsole.log("check" , getSeminarPublish)
    }
    const hideModal = () => {
        setModalShow(false);
    }
    const showModal = () => {
        setModalShow(!modelShow);
    }
    const readMoreFunction = (buttonId, seminar) => {
     
        if (buttonId === 'readMore') {
            // setISValid(true);
            history.replace({ pathname: `/Readmore/${seminar?.subtenantId}/${seminar?.seminarId}` })

        }
        else {
            // showModal();
            history.push({ pathname: '/Register', state: { seminarobj: seminar } })
        }
    }
    const showEventList = () => {
        setISValid(false);
    }

    const getSeminarPublish = useCallback((subtenantId,isActive,seminarType) => {
        konsole.log("check1",subtenantId,isActive,seminarType)
        // let seminarType = "UPCOMING";
        setLoader(true);
        const promise = Services.getSeminarPublisherDetailID(subtenantId,isActive,seminarType);
        promise.then(response => {
            setLoader(false);
            konsole.log("Seminar Date1", response.data.data);
            konsole.log("seminarObj", response.data.data);
            setSeminarList(response.data.data);
            hideModal();
        }).catch((e) => {
            konsole.log('Error Catched: ', e.response);
        }).finally(() => {
            setLoader(false);
        });
    }, [])
    // konsole.log("Valid", isValid);
    konsole.log("Eventlsin", seminarObj);
    const data1 = (seminar, toDate) => {
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var d = new Date(seminar);
        var dayName = days[d.getDay()];
        var d2 = new Date(toDate);
        var dayName2 = days[d2.getDay()];
        return "(" + dayName + "-" + dayName2 + ")"
    }


    return (
        <div className='container-fluid p-0 bg-white' ref={topContainerRef} >
            {/* {
                modelShow &&
                <ModalComponent modelShow={modelShow} showModal={showModal}>
                 </ModalComponent> 
            } */}
            {/* <Registration seminarObj={seminarObj} buttonId={buttonId.current} callAPiFromPopup={callAPiFromPopup} /> */}

            {/* <Registration seminarObj={seminarObj} buttonId={buttonId.current} callAPiFromPopup={callAPiFromPopup} /> */}
            <EventHeader subtenantId={subtenantKey} />
            <EventMenu subtenantId={subtenantKey} />


            {
                (subtenantKey == 26) ?
                    <div className='container-fluid py-2' style={{ backgroundColor: 'rgb(164, 164,164, .2)' }}>
                        <div className='row' >
                            <div className='col'>
                                <div className='row '>
                                    <div className='col d-flex justify-content-center'>
                                        <h1 style={{ color: "maroon" }}>Dinner with Rajiv</h1>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col text-center'>
                                        <h5 className="">What legacy will you leave behind? Learn how you can be part of revolutionizing the way Americans plan for retirement.</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    :
                    <div className='container-fluid py-2' style={{ backgroundColor: 'rgb(164, 164,164, .2)' }}>
                        <div className='row' >
                            <div className='col'>
                                <div className='row '>
                                    <div className='col d-flex justify-content-center'>
                                        {
                                            (subtenantIdKay === 745) ?
                                                <>
                                                    <h1 style={{ color: "maroon" }}>Live Workshop</h1>
                                                </>
                                                :
                                                <>
                                                    <h1 style={{ color: "maroon" }}>Live Events</h1>
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col d-flex justify-content-center'>
                                        <h2>How to age on your terms</h2>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-7 m-auto ' style={{ textAlign: "center" }}>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            }


            <div className='container bg-white pb-3 ' style={{ position: "relative" }} >
                <div className="bg-white" style={{ position: "sticky", top: "0", zIndex: "3" }}>
                    <div className="d-flex w-100 mb-2" style={{ borderBottom: "1px solid black" }}>
                        <div className="w-100" >
                            <Searchcomponent subtenantId={subtenantKey} setSearch={setSearch} setIsPaid={setIsPaid} setdirectwebinar={setdirectwebinar} />
                        </div>
                        <div className="Qr-Code-Main-Div"  >
                            <QRCode

                                value={`${EventBaseUrl}eventlisting/${subtenantIdKay}`}
                                viewBox={`0 0 256 256`}
                                onClick={handleShow}
                                className="QR-code-inner"

                            />

                            <h6 className="text-center Qr-code-text-h6">View QR</h6>
                        </div>
                    </div>
                </div>
                <div >  {/* Search Card Start */}

                    {/* <div className="w-100">
                        
                    </div> */}



                    <Modal size="sm" show={show} onHide={handleClose} >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>

                            <QRCode
                                style={{ width: "100%" }}
                                value={`$${EventBaseUrl}eventlisting/${subtenantIdKay}`}
                                viewBox={`0 0 256 256`}
                            />

                        </Modal.Body>
                    </Modal>

                    {/* Search Card End */}

                    {

                        seminarList.length > 0 && filteredData().map((seminar, index) => {
                            console.log("xaa", seminar)
                            const toDate = seminar.remarks.slice(0, 11);
                            const toTime = seminar.remarks.slice(11, 31);
                            const weekDay = seminar.remarks.slice(20, 40)
                            const noOfRegisteredSeats = seminar.noOfRegisteredSeats;
                            const noOfSeats = seminar.noOfSeats;
                            const opacity = (noOfRegisteredSeats >= noOfSeats) ? { opacity: '.12' } : {};

                            return (
                                <>
                                    <div >
                                        <div className='container bg-white' style={{ position: "relative", }} >
                                            <div className="row mb-3 pt-1 " style={{ border: "1px dotted black " }}>
                                                <div className="col " >

                                                    <div className='row pt-2 ps-1 px-1'>
                                                        <div className='col-6 col-xs-8'>
                                                            <h4 className='retired-Life-h1-tag '>
                                                                <b>{seminar.seminarTopic} </b>
                                                                {
                                                                    subtenantKey !== '26' && <span>({seminar.seminarType}, {seminar.isSeminarPaid == true ? 'Paid' : 'Free'}{seminar.isSeminarPaid == true ? " : " + "$" + seminar.seminarFee : ""})</span>
                                                                }
                                                            </h4>
                                                        </div>
                                                        <div className='col-6 col-xs-4  d-flex justify-content-end position-relative'>
                                                            <button type="button" className="btn p-0 px-sm-2 btn-outline-dark register-btn" onClick={() => readMoreFunction("registerNow", seminar)}>Register Now</button>
                                                        </div>
                                                    </div>
                                                    <div className="row p-0">
                                                        <div className="col-12">
                                                            <h5 className='adviser-tag-h4 m-0'>By:
                                                                {
                                                                    seminar.speakers.length > 0 && seminar.speakers.map((speaker, index) => {
                                                                        const fName = (speaker.fName !== null && speaker.fName !== '') ? speaker.fName : '';
                                                                        const mName = (speaker.mName !== null && speaker.mName !== '') ? speaker.mName : ' ';
                                                                        const lName = (speaker.lName !== null && speaker.fName !== '') ? speaker.lName : '';
                                                                        return <span>{` ${fName + " " + mName + " " + lName} ${(seminar.speakers.length - 1 === index) ? '' : ', '}`}</span>

                                                                    })
                                                                }
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div className="row p-0">
                                                        <div className="col-12 m-0">
                                                        {/* {commonLib.getFormattedDate(seminar?.seminarDate)} To {commonLib.getFormattedDate(toDate)} {weekDay == 1 ? "(Sat-Sun)" : data1(seminar?.seminarDate, toDate, weekDay) */}
                                                            <h6 className='m-0'>On {subtenantIdKay == 745 ? (`${commonLib.getFormattedDate(seminar?.seminarDate)} To ${commonLib.getFormattedDate(toDate)} ${weekDay == 1 ? "(Sat-Sun)" : data1(seminar?.seminarDate, toDate, weekDay)} At ${commonLib.getFormattedTime(seminar?.seminarTime)} To ${commonLib.getFormattedTime(toTime)}`) 
                                                            : (`${commonLib.getFormattedDate(seminar.seminarDate)}, at ${commonLib.getFormattedTime(seminar.seminarTime)}`)}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {
                                                            (seminar?.seminarType === "Webinar") ?

                                                                <></>
                                                                :
                                                                <>
                                                                    <div className="col-12">
                                                                        <h6 className='date-Time-tag-h4 m-0'>Venue : {seminar.seminarVenue}</h6>
                                                                        <h6 className='date-Time-tag-h4 m-0'>Location : {seminar.venueAddressLine1}</h6>
                                                                    </div>
                                                                </>

                                                        }
                                                    </div>
                                                    <div className="row ">
                                                        <div className="col-10 pe-0 seminarDescriptionDiv" style={{ width: "auto", maxWidth: "200px" }}>
                                                            <p className='adviser-tag-h4 mt-2 ' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} >
                                                                {seminar.description}


                                                            </p>
                                                        </div>
                                                        <div className="col-2 mt-2 p-0" style={{ width: "auto" }}>
                                                            <a type="button" className="text-decoration-underline text-dark ms-1" onClick={() => readMoreFunction("readMore", seminar)}>
                                                                Read More
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                (noOfRegisteredSeats >= noOfSeats) &&
                                              <div>
                                                <div className="position-absolute top-0  text-center " style={{ height: '100%', width: '100%', backgroundColor:"white",opacity:".9"}}>
                                                    <div style={{}} >

                                                       

                                                    </div>
                                                    <span style={{ opacity: 100 }}> <img src="/icons/soldout.png" width={100} style={{ width: '100%', maxWidth: '200px' }} />
                                                    </span>
                                                    <p className="m-0 fs-5" style={{color:"black",opacity:13}}>For any queries <br /> please  contact on {supportEmail}</p>

                                                </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            )
                        })}



                </div>


            </div>




            {/* Inner Card End */}


            {/* footer div start */}

            {
                subtenantKey !== '26' &&
                <SeminarListingFooter />
            }





        </div>
    )
}

export default Eventlisting
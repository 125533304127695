import React, { useContext, useState ,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../App';
import commonLib from '../../control/commonLib';
import { selfCheckInId, selfPlannerId , selfPlannerCheckedIn} from '../../control/Constant';
import konsole from '../../network/konsole';
import Services from '../../network/Services';
import Toaster from '../../Toaster/Toaster';
import LayoutComponent from '../LayoutComponent';
import '../ClassroomComponent/register.css'
// import './SignUp.css';
// import AO from './AO-Logo-Only1.jpg';
export default function SelfPlanner(props) {
  const { setLoader } =  useContext(UserContext);
  

  const history = useHistory();
  const seminarObj = commonLib.getObjFromStorage('seminarObj');
  const venueObj = commonLib.getObjFromStorage('venueObj');
  const classroomObj = commonLib.getObjFromStorage('classroomObj');
  const [checkinField, setCheckInField] = useState('');
  const [error, setError] = useState(false);

// // konsole.log("gdd",seminarObj)
// // const subtenantId = seminarObj?.seminarSubtenantId;
// //     const seminarId = seminarObj?.seminarId;
// //     konsole.log("zz",subtenantId,seminarId,selfPlannerCheckedIn)
 
useEffect(() => {
 const classStatus1 = true;
 commonLib.setDatatoSession("classStatus1",JSON.stringify(classStatus1))
},[])


  const handleChange = (event) => {
    konsole.log("eventevent",event)
    setError(false);
    setCheckInField(event.target.value)
  }
  // konsole.log("zz",checkinField)

  const checkinStatusSubmit = (event) => {
    const linkUser = true
    commonLib.setDatatoSession("linkUser",JSON.stringify(linkUser))
  

    event.preventDefault();

    if(checkinField == ""){
      setError(true);
      setCheckInField('');
    }else{
      const subtenantId = seminarObj?.seminarSubtenantId;
      const seminarId = seminarObj?.seminarId;
      konsole.log("zz",subtenantId, seminarId)
  
      validateSeminarAttendee(subtenantId, seminarId, checkinField, "CLASSROOM");
    }
  }


//   const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy) => {
//     setLoader(true);
//     const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
//     promises.then(res => {
//       setLoader(false);
//       konsole.log('registered Checkin res', res);
//       const selfWalkInCheckInObj = res.data;
//       // commonLib.setDatatoSession("plan", JSON.stringify(selfWalkInCheckInObj))

      
//     })
//       .catch(err => {
//         konsole.log("err2", err);
//         // Toaster.error(err.response.data.messages[0])
//       }).finally(fin => {
//         konsole.log("finish");
//         setLoader(false);
//       })
//   }


  const validateSeminarAttendee = (subtenantId, seminarId, contact, status) => {
    

    setLoader(true);

    const promises = Services.validateSeminarAttendee(subtenantId, seminarId, contact, status);
    konsole.log("status",subtenantId, seminarId, contact, status)
    promises.then(res => {
      setLoader(false);
      konsole.log("responsvie", res);
      const validateUserResponse = res.data.data[0];
      commonLib.setDatatoSession("plan", JSON.stringify(validateUserResponse))
      konsole.log("validateUserResponse", validateUserResponse);
      const attendeeSeminarId = validateUserResponse.seminarAttendeeId;
      const attendeeUserId = validateUserResponse.seminarAttendeeUserId;
      const classroomObj = commonLib.getSeminarAttendee(validateUserResponse);
      konsole.log("classroomObj", validateUserResponse.seminarAttendeeId);
      commonLib.setSessionStoarge('classroomObj', JSON.stringify(classroomObj));
      // handleWalkInCheckInUser(seminarId, attendeeSeminarId, attendeeUserId, selfPlannerId, attendeeUserId);
      history.push({
      pathname: '/Classroomreg',
      state : validateUserResponse
    })

    })

   
    // konsole.log("rest",validateUserResponse)
      .catch(err => {
        konsole.log("err1", err.response);
        Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  const newRegister =()=>{
    const newRegister = true
    commonLib.setDatatoSession("newRegisterUserDat",JSON.stringify(newRegister))    ////
    // commonLib.setDatatoSession("newRegisterStatus",JSON.stringify(true))
    history.push({
      pathname: '/Classroomreg'
      
    })
    
  }

  return (
    <LayoutComponent>
      <div>
        <div className='container-fluid bg-light selfClassroom-main main-div' >
          <div className='row'>
            <div className='col-xs-12 col-lg-6' >
              <div className='row ms-3 me-1 planner-content-div'>
                <div className='col main-title-div'>
                  <div className=''>
                    <p style={{ color: "#B06A0A" }} className="fs-1 mb-1 fw-bold">Book A</p>
                  </div>
                  <div className=''>
                    <h1 style={{ color: "#B06A0A" }} className="fs-1  fw-bold">Workshop</h1>
                  </div>
                </div>
                <div className='row'>
                  <p className='mobile-lable  fw-100' style={{color:' #820024',fontFamily:'sans-serif'}}>
                  Cell Number or Email
                  </p>
                
                  <div className=''>
                    <input type="text" className='form-control border-0 p-3 planner-input' placeholder='Enter Number or Email' 
                    onChange={(event) => handleChange(event)} value={checkinField} 
                    />
                    <p className='text-danger text-bold mt-2 ms-2'> 
                    {error && 'Entered Mobile No or Email Id does not match'}
                    </p>
                  </div>
                  <div>
                    <button className="signup-btn fs-4 border-0 p-2 w-100 h-100 mt-2" 
                    onClick={checkinStatusSubmit}
                    >Book Workshop</button>
                  </div>
                  <div className='mt-2'>
                  <p className='fs-3 text-center mt-4'>(OR)</p>
                  </div>
                  <div>
                  
                    <button className="btn fs-4 border-0 p-2 w-100" style={{ backgroundColor: "maroon", color: "white", height:"62px"}}
                    onClick={()=>newRegister()}
                    >New Registration</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col sign-colm d-none d-lg-block'>
              <img src="../../s1.jpg" className="img-fluid image" />
            </div>
          </div>
        </div>
      </div >
    </LayoutComponent>
  );
};










import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../App';
import commonLib from '../../../control/commonLib';
import { selfCheckInId, selfPlannerId , selfPlannerCheckedIn} from '../../../control/Constant';
import konsole from '../../../network/konsole';
import Services from '../../../network/Services';
import Toaster from '../../../Toaster/Toaster';
import LayoutComponent from '../../LayoutComponent';
import './SignUp.css';
// import AO from './AO-Logo-Only1.jpg';
export default function SelfPlanner(props) {
  const { setLoader } =  useContext(UserContext);
  

  const history = useHistory();
  const seminarObj = commonLib.getObjFromStorage('seminarObj');
  const venueObj = commonLib.getObjFromStorage('venueObj');
  const attendeeObj = commonLib.getObjFromStorage('attendeeObj');
  const [checkinField, setCheckInField] = useState('');
  const [error, setError] = useState(false);

// konsole.log("gdd",seminarObj)
// const subtenantId = seminarObj?.seminarSubtenantId;
//     const seminarId = seminarObj?.seminarId;
//     konsole.log("zz",subtenantId,seminarId,selfPlannerCheckedIn)


  const handleChange = (event) => {
    setError(false);
    setCheckInField(event.target.value)
  }
  // konsole.log("zz",checkinField)

  const checkinStatusSubmit = (event) => {
    event.preventDefault();

    if(checkinField == ""){
      setError(true);
      setCheckInField('');
    }
    
    const subtenantId = seminarObj?.seminarSubtenantId;
    const seminarId = seminarObj?.seminarId;
    // konsole.log("zz",subtenantId)

    validateSeminarAttendee(subtenantId, seminarId, checkinField, selfPlannerCheckedIn);
  }


  const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy) => {
    setLoader(true);
    const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
    promises.then(res => {
      setLoader(false);
      konsole.log('registered Checkin res', res);
      const selfWalkInCheckInObj = res.data;
      // commonLib.setDatatoSession("plan", JSON.stringify(selfWalkInCheckInObj))

      
    })
      .catch(err => {
        konsole.log("err2", err);
        // Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }


  const validateSeminarAttendee = (subtenantId, seminarId, contact, status) => {
    setLoader(true);
    const promises = Services.validateSeminarAttendee(subtenantId, seminarId, contact, status);
    promises.then(res => {
      setLoader(false);
      // konsole.log("responsvie", res);
      const validateUserResponse = res.data.data[0];
      commonLib.setDatatoSession("plan", JSON.stringify(validateUserResponse))
      // konsole.log("validateUserResponse", validateUserResponse);
      const attendeeSeminarId = validateUserResponse.seminarAttendeeId;
      const attendeeUserId = validateUserResponse.seminarAttendeeUserId;
      const attendeeObj = commonLib.getSeminarAttendee(validateUserResponse);
      // konsole.log("attendeeObj", validateUserResponse.seminarAttendeeId);
      commonLib.setSessionStoarge('attendeeObj', JSON.stringify(attendeeObj));
      // handleWalkInCheckInUser(seminarId, attendeeSeminarId, attendeeUserId, selfPlannerId, attendeeUserId);
    })

    history.push({
      // pathname: '/VenuePayment',
      // state : selfWalkInCheckInObj
      pathname: '/AfterPaymentScreen'
    })
      .catch(err => {
        // konsole.log("err1", err.response);
        Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }
  return (
    <LayoutComponent>
      <div>
        <div className='container-fluid bg-light self-palnner-main main-div' >
          <div className='row'>
            <div className='col-xs-12 col-lg-6' >
              <div className='row ms-3 me-1 planner-content-div'>
                <div className='col main-title-div'>
                  <div className=''>
                    <p style={{ color: "#B06A0A" }} className="fs-1 mb-1 fw-bold">Book A</p>
                  </div>
                  <div className=''>
                    <h1 style={{ color: "#B06A0A" }} className="fs-1  fw-bold">Appointment Booking</h1>
                  </div>
                </div>
                <div className='row'>
                  <p className='mobile-lable  fw-100' style={{color:' #820024',fontFamily:'sans-serif'}}>
                  Cell Number or Email
                  </p>
                
                  <div className=''>
                    <input type="text" className='form-control  self-planner-small border-0 p-3 planner-input' placeholder='Enter Number or Email' onChange={(event) => handleChange(event)} value={checkinField} />
                    <p className='text-danger text-bold mt-2 ms-2'> {error && 'Entered Mobile No or Email Id does not match'}</p>
                  </div>
                  <div>
                    <button className="signup-btn signup-btn-counter-planner fs-4 border-0 p-2 w-100 h-100 mt-2" onClick={checkinStatusSubmit}>Book an Appointment</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col sign-colm d-none d-lg-block '>
              <img src="../../s1.jpg" className="img-fluid image" style={{overflow:'hidden'}} />
            </div>
          </div>
        </div>
      </div >
    </LayoutComponent>
  );
};










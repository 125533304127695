import React from 'react'
import "../commonComponent/commoncomponent.css"

const Commonregcomponent = (props) => {

    return (
        <div>
            <div className='d-flex'>
                <h5 className='ClassReg-Tag-h4'>Workshop Registration: &nbsp;</h5>
                <h5 className='ClassReg-Tag-h4' style={{fontWeight:500}}>
                    {props.text!=""?props.text:""}
                </h5>
            </div>
            <div className='d-flex'>
                <h6 className='ClassReg-Tag-h4'>
                    Topic : &nbsp;</h6>
                <h6 className='ClassReg-Tag-h4'>{props.topic!=""?props.topic:""}</h6>
            </div>
        </div>
    )
}

export default Commonregcomponent
import React from 'react'
import { Row, Col, Typography, Input, Button } from "antd";

const { Title } = Typography;

const SeminarListingFooter = () => {
  return (
    <>
      <div class="footer-top py-5" style={{ backgroundColor: "#4C4744", color: "white" }} >
        <div class="container">
          <div class="row flex-lg-row-reverse">
            <div class="col-lg-6">
              {/* <p className='fs-4'>Sign up for the AgingOptions Newsletter</p>
              <form id="formSubscribe" class="mb-4">
                <input type="email" required name="Email" className='w-75 ' placeholder="Enter your email" style={{
                  borderBottomLeftRadius: "50px",
                  borderTopLeftRadius: "50px",
                  height: 40

                }} /><input type="submit" value="SIGN UP" style={{
                  height: 37, marginLeft: 5, borderBottomRightRadius: "50px",
                  borderTopRightRadius: "50px", backgroundColor: "#D79B48", color: "#D79B48", border: "none"
                }} />
              </form> */}
              <div class="alert alert-success  d-none" role="alert">
                Thank you for subscribing to our Newsletter.
              </div>

            </div>
            <div class="col-lg-2 d-none d-lg-flex"></div>
            <div class="col-lg-4">
              <img src="/icons/AgingOptionsWhiteLogo.svg" className='img-fluid' />

              <p className='fs-3'>About Us</p>
              <p>AgingOptions helps people and the professionals who guide them to do a better job of planning for retirement.</p>
            </div>
          </div>
        </div>
      </div>





      <div class="footer-bottom" style={{ backgroundColor: "#4C4744", color: "white" }} >
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6 footer-contact">
              <p className='fs-3'>Contact Us</p>
              <p>
                AgingOptions<br />
                31919 Sixth Avenue South<br />
                Federal Way, WA 98003<br />
                United States <br /><br />
                <strong>Cell:</strong> 1.877.762.4464<br />1.877.76.AGING<br />
                <strong>Email:</strong> Info@AgingOptions.com<br />

              </p>

            </div>

            <div class="col-lg-3 col-md-6 footer-links ">
              <h4>&nbsp;</h4>
              <p >About<br />
                Meet the Founder<br />
                Support<br />
                Privacy Policy<br />
                Terms of Service</p>
            </div>

            <div class="col-lg-2 col-md-6 footer-links">
              <p className='fs-3 '>Learn</p>
              <p >Award winning Blog
                <br />Free Learning center</p>

            </div>
            <div class="col-lg-2 col-md-6 footer-links">
              <p className='fs-2'>Join</p>
              <p>The Portal<br />
                The DIY Experience<br />
                The Concierge Experience</p>

            </div>


          </div>
        </div>
      </div>


      <div class="container-fluid pb-2" style={{ backgroundColor: "#4C4744", color: "white" }} >
        <div className='col-md-12 col-xs-12 text-white'>
          <p className='text-center'>@CopyrighAgingOption.All Right Reserved</p>
        </div>
      </div>
    </>
  )
}

export default SeminarListingFooter
import React from 'react'
import Footer from './Footer/Footer'
import Header from './Header/Header'

function LayoutComponent({children}) {
  return (
    <div>
        <Header />
          <div className='contentArea'>
            {children}
          </div>
        <Footer />
    </div>
  )
}

export default LayoutComponent;
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from "antd"
import './modalcomponent.css';

const ModalComponent = (props) => {

   const footer  = () => {
      return (
      <Row style={{ backgroundColor: "#454545", marginTop: "8px"}}>
        <Col span={24}>
          <Row >
            <Col span={24}>
              <h6 style={{ display: "flex", justifyContent: "center", color: "white", marginTop: "10px" }}>Powered By</h6>
            </Col>
          </Row>
          <Row>
            <Col span={24} className="text-center py-2" style={{ display: "flex", justifyContent: "center", color: "white" }}>
              {/* <h4 >AGINGOPTIONS</h4> */}
                <img  src="/icons/AgingOptionsWhiteLogo.svg"  />
            </Col>
          </Row>
        </Col>
      </Row>
      )
   }

  return (
    <div>
      <Modal style={{ top: 20 }} maskClosable={false}  bodyStyle={{ padding: 0 }} visible={props.modelShow} onCancel={props.showModal}
        //  okButtonProps={isModelVisibel}
        footer={[footer()]}
        closable={true}
      >
        {props.children}
      </Modal>
    </div>
  )
}

export default ModalComponent
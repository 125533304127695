import React, { useState } from 'react'
import konsole from '../../../network/konsole'
import "./search.css"
import commonLib from '../../../control/commonLib'
const Searchcomponent = ({ setSearch, setIsPaid, setdirectwebinar, subtenantId }) => {
    const subtenantIdKay = commonLib.getObjFromStorage("subtenantId");



    const isCheckboxfree = (e) => {
        if ("checked" == e) {
            document.getElementById("priorityLow").checked = false;

        }
    }

    const isCheckboxPaid = (e) => {
        if ("checked" == e) {
            document.getElementById("priorityHigh").checked = false;
        }
    }

    const isCheckboxDirect = (e) => {
        if ("checked" == e) {
            document.getElementById("priorityLow1").checked = false;
        }
    }

    const isCheckboxWebinar = (e) => {
        if ("checked" == e) {
            document.getElementById("priorityHigh1").checked = false;
        }
    }



    const checkboxdirect = (e) => {
        if (e.target.checked == true) {
            setdirectwebinar(46)


        }
        // else{
        //     setdirectwebinar(null)
        // }
    }

    const checkboxwebinar = (e) => {
        // konsole.log("ash",e.target.checked)
        if (e.target.checked == true) {
            setdirectwebinar(47)

        }
        else {
            setdirectwebinar(46)
        }

    }


    const checkboxfree = (e) => {
        konsole.log("abcde", e.target.checked)
        if (e.target.checked == true) {
            setIsPaid(false);
            // setFreeState(true)
            konsole.log("aaaaaa", e.target.checked)

        } else {
            setIsPaid(null)
        }

    }
    const checkboxpaid = (e) => {
        // konsole.log("rt",e.target.checked)
        if (e.target.checked == true) {

            setIsPaid(true)
            // setPaidState(true)

        } else {
            setIsPaid(null)
        }


    }


    // konsole.log("checked",checke);
    return (
        <div   >

            <div className=' bg-white' id='searchComponent' >
                <div className='container bg-white mb-2' >
                    {
                        subtenantId !== '26' &&
                        <div className='row'>
                            <div className='col input-group mt-2  Serach-Input-Box-Div'>
                                <input type="text" class="form-control" placeholder={subtenantIdKay == 745? "Search for the Speaker or Workshop" : "Search for the Speaker or Seminar" }onChange={(e) => setSearch(e.target.value)} />
                                <a href="#" style={{ backgroundColor: "#F4F6F6" }}><img className='' height={40} src="/icons/eventSearchIngIcon.svg" /></a>
                            </div>
                        </div>
                    }
                    <div className='row mt-2 d-flex align-items-center mb-2 mb-md-0' >
                        <div className='col-md-6 col-lg-6 col-sm-12 col Serach-Text-Div'>
                            <h1 class="title-tag title-taggs-H1">{subtenantIdKay == 745 ? "Upcoming Workshops":"Upcoming Events"}</h1>
                        </div>
                        {
                            subtenantId !== "26" &&
                            <div className='col-md-6 col-lg-6 col-sm-12  '>
                                <div className='row  Main-checkBox-Div'>


                                    <div className='col-2  d-flex justify-content-around'>
                                        <div class="d-flex gap-2 checkBox-text-Div checkBox-text-Div-Input" >
                                            <input type="checkbox" class="checkmark " name="priorityHigh" id="priorityHigh" onChange={checkboxfree} onClick={() => isCheckboxfree("checked")} />

                                            Free
                                        </div>
                                    </div>
                                    <div className='col-3 d-flex justify-content-around '>
                                        <div class="d-flex gap-2 checkBox-text-Div" >
                                            <input type="checkbox" name="priorityLow" id="priorityLow"  onChange={checkboxpaid} onClick={() => isCheckboxPaid("checked")} />
                                            Paid
                                        </div>
                                    </div>

                                    <div className='col-4 d-flex justify-content-around    '>
                                        <div class="  d-flex gap-2 checkBox-text-Div" >
                                            <input type="checkbox" name="priorityHigh1" id="priorityHigh1" onChange={checkboxdirect} onClick={() => isCheckboxDirect("checked")} />
                                            In-Person
                                        </div>
                                    </div>
                                    <div className='col-3 d-flex justify-content-around '>
                                        <div class="d-flex gap-2 checkBox-text-Div" >
                                            <input type="checkbox" name="priorityLow1" id="priorityLow1" onChange={checkboxwebinar} onClick={() => isCheckboxWebinar("checked")} />
                                            Webinar
                                        </div>
                                    </div>

                                    {/* <div className='col-4  mt-2 d-flex justify-content-around'>
                                        <div class="form-check  me-3 d-flex  align-items-center">
                                            <input class="form-check-input" type="checkbox" name="flexRadioDefault1" id="flexRadioDefault1" onChange={checkboxdirect} />
                                            <label class="form-check-label ms-1" for="flexRadioDefault1"> In-Person </label>
                                        </div>
                                    </div>
                                    <div className='col-3 d-flex justify-content-around ml-2 mt-2'>
                                        <div class="form-check d-flex  align-items-center gap-2">
                                            <input class="form-check-input" type="checkbox" name="flexRadioDefault1" id="flexRadioDefault1" onChange={checkboxwebinar} />
                                            <label class="form-check-label" for="flexRadioDefault1"> Webinar </label>
                                        </div>
                                    </div> */}

                                </div>

                            </div>
                        }
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Searchcomponent
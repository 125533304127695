import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import SelfPlanner from "./component/SelfPlanner/Singn/SelfPlanner";
import Header from "./component/Header/Header";
import SelfChecking from "./component/selfchecking/index/SelfChecking";
import Registration from "./component/selfchecking/RegisterNow/Registration";
import VenuePayment from "./component/SelfPlanner/VenuePayment/VenuePayment";
import Dashboard from "./component/Dashboard/Dashboard";
import { BrowserRouter, Link, Route, Switch, useLocation, useParams } from "react-router-dom";
import Congratulations from "./component/selfchecking/Congratulations/Congratulations";
import AfterPaymentScreen from './component/AfterPaymentScreen'
import Footer from "./component/Footer/Footer";
import ConfirmBooking from './component/ConfirmBooking';
import EventListing from './component/EventListing/Eventlisting'
import React, { useEffect, useState } from "react";
import konsole from "./network/konsole";
import commonLib from "./control/commonLib";
import ReadMore from "./component/Enquiry/ReadMore/Readmore";
import SelfClassroom from "./component/ClassroomComponent/SelfClassroom";
import Classroomregistration from "./component/ClassroomComponent/Classroomregistration";
import Classroomvenupayment from "./component/ClassroomComponent/classroomPayment/Classroomvenupayment";
import Readmoreclassroom from "./component/ClassroomComponent/Readmoreclassroom/Readmoreclassroom";
import classRoomNewRegistration from "./component/ClassroomComponent/classRoomNewRegister"
import Mailtocoordinator from "./component/Mailtocoordinator";
import QrCodeScanner from "./component/QrCodeScanner";
import Subtenant from "./component/SelfCheckingByQr";
import SelfCheckingByQr from "./component/SelfCheckingByQr";
import { isNotValidNullUndefinedfile } from "./control/Constant";

export const UserContext = React.createContext({
  subtenantLogo: '',
  loader: false,
});

function App(props) {
  const [subtenantLogo, setSubtenantLogo] = useState('');
  const [loader, setLoader] = useState(false);

  const seminarObj = commonLib.getObjFromStorage('seminarObj');
  

  useEffect(()=>{
    if (isNotValidNullUndefinedfile(seminarObj)) {
      setSubtenantLogo(seminarObj.logoUrl || seminarObj?.subtenantLogoUrl);
    }
  },[seminarObj])


  // const seminarObj = commonLib.getObjFromStorage('seminarObj');
  // konsole.log("seminarObj", seminarObj);
  // const subtenantLogo = (seminarObj !== undefined) ? seminarObj?.logoUrl : '';

  return (
    <UserContext.Provider value={{subtenantLogo: subtenantLogo, setSubtenantLogo, loader: loader, setLoader}}>
      <div className="overlay position-relative">
        { loader &&
          <div class="d-flex align-items-center justify-content-center position-absolute overLayLoader">
            <div class="spinner-border" role="status" aria-hidden="true"></div>
          </div>
        }
        <BrowserRouter>
            <Switch>
              <Route path="/Register" exact component={Registration} />
              <Route path="/Congratulations" exact component={Congratulations} />
              <Route path="/SelfChecking/" exact component={SelfChecking} />
              <Route path="/UserBooking" exact component={VenuePayment} />
              <Route path="/selectBooking" exact component={AfterPaymentScreen} />
              <Route path="/SelfPlanner" exact component={SelfPlanner} />
              <Route path="/ConfirmBooking" exact component={ConfirmBooking} />
              <Route path="/EventListing/:subtenantKey?" exact component={EventListing} />
              <Route path="/EventListing/:subtenantKey?/:seminarid?" exact component={ReadMore} />
              <Route path="/Readmore/:subtenantKey?/:seminarid?" exact component={ReadMore} />
              <Route path="/AfterPaymentScreen" exact component={AfterPaymentScreen} />
              <Route path='/Account' exact component={Dashboard} />
              <Route path="/Classroomreg" exact component={Classroomregistration}/>
              <Route path="/classroomvanupayment" exact component={Classroomvenupayment}/>
              <Route path="/readmoreprofessclassroom" exact component={Readmoreclassroom}/>
              <Route path="/selfClassroom" exact component={SelfClassroom} />
              <Route path="/newClassroomRegistration" exact component={classRoomNewRegistration} />
              <Route path="/Mailtocoordinator" exact component={Mailtocoordinator} />
              <Route path="/qrcodescanner" exact component={QrCodeScanner} />
              <Route path="/SelfcheckbyQr" exact component={SelfCheckingByQr} />
              {/* <Route path="/qrcodescanner" exact c
              omponent={QrCodeScanner} /> */}
              
              
            </Switch>
        </BrowserRouter> 
       {/* <SelfCheckingByQr/> */}
      </div>
    </UserContext.Provider>
  );
}

export default App;

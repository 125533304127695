
import { Col, Input, Row } from 'antd'
import React, { useEffect, useState, useCallback, useContext } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import LayoutComponent from '../LayoutComponent'
import "../ClassroomComponent/ClassroomRegistration.css"
import { useHistory } from 'react-router-dom';
import Commonregcomponent from './commonComponent/Commonregcomponent';
import Services from '../../network/Services';
import konsole from '../../network/konsole';
import { UserContext } from '../../App';
import commonLib from '../../control/commonLib';
import Payment from '../SelfPlanner/VenuePayment/Payment';

const Classroomregistration = (props) => {
    const classRoomStatus = commonLib.getObjFromStorage("newRegisterUserDat");
    const [readMore, setReadMore] = useState(false);
    const [seminarList , setSeminarList] = useState([])
    const [showPayment, setshowPayment] = useState("")
    const [classRoomDataObj, setclassRoomDataObj] = useState({})
    const { setLoader, setSubtenantLogo } = useContext(UserContext);
    const [error, seterror] = useState("")
    const SelectedUser = (props.location.state !== undefined)? props.location.state :""
    const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
    konsole.log("send",SelectedUser,classRoomDataObj)
    const history = useHistory()

    const readMoreFunction = () => {
        setReadMore(true)
    }

    useEffect(()=>{
        getSeminarPublish(745, true ,"UPCOMING");
        
    },[])
    const classRoomRegistration =(userId,seminarAttendeeId,seminarId,classroomId,createdBy)=>{
        let postData = Services.postClassRoomRegister(userId,seminarAttendeeId,seminarId,classroomId,createdBy)
        konsole.log("mew",userId,seminarAttendeeId,seminarId,classroomId,createdBy)
        postData.then((res) => {
    
       
          konsole.log("responseresponse", res.data.data)
          let classbookingId = res.data.data?.bookingId;
          commonLib.setDatatoSession("classbookingId", JSON.stringify(classbookingId))


    
        }).catch((err) => {
          konsole.log("errWW", err)
        })
      }
    const getSeminarPublish = useCallback((subtenantId1,isActive,seminarType) => {
        // konsole.log("check", subtenantId)
        // let seminarType = "UPCOMING";
        setLoader(true);
        const promise = Services.getSeminarPublisherDetailID(subtenantId1,isActive,seminarType);//, true, seminarType
        setLoader(true); 
        promise.then(response => {
            konsole.log("rest",response.data.data)
            setLoader(false);
            // konsole.log("Seminar Date", response.data.data[0].seminarDate);
            konsole.log("seminarObjClass", response.data.data);
            let toDate = response.data.data[7]?.remarks.slice(0,11)
            let toTime = response.data.data[7]?.remarks.slice(11,31)
            konsole.log("data$time", toDate)
            setSeminarList(response.data.data);
            // hideModal();
        }).catch((e) => {
            konsole.log('Error Catched: ', e.response);
            if(e.response.data.message === "No Data Found"){
                seterror(e.response.data.message)
                setLoader(false)
            }else{
                setLoader(false)
            }
        }).finally(() => {
            setLoader(false);
        });
    }, [])

   const handleClassAttSelect = (index,classData) =>{
konsole.log("indexindex",index)
let selectedClassAtt = seminarList[index].speakers[0]
let selectedClassAttSem = seminarList[index]
konsole.log("selectedClassAtt",selectedClassAttSem)

const classRoomData = {
    classBooking : "classBooking",
    seminarId : selectedClassAttSem?.seminarId,
    seminarTopic : selectedClassAttSem?.seminarTopic,
    seminarType : selectedClassAttSem?.seminarType,
    seminarDate : selectedClassAttSem?.seminarDate?.slice(0,11),
    seminarTime : selectedClassAttSem?.seminarTime?.slice(11,31),
    toDate : selectedClassAttSem?.remarks.slice(0,11),
    toTime : selectedClassAttSem?.remarks.slice(11,31),
    seminarFee : selectedClassAttSem?.seminarFee,
    speaker : selectedClassAttSem?.speakers[0],
}
const allData = {classRoomData:classRoomData,useData:SelectedUser}
   
     history.push({ 
    pathname: '/readmoreprofessclassroom', 
    state:  allData } )
      
   }


   const handleClassAttToPay = (index,classData) =>{
    setLoader(true)
    konsole.log("indexindex",index)
    
  const simplelinkBook = true;
    commonLib.setDatatoSession("simplelinkBook",JSON.stringify(simplelinkBook))
    commonLib.setDatatoSession("classRoomSeminar",JSON.stringify(classData))
    let selectedClassAttSem = seminarList[index]
    konsole.log("seminarIdseminarId",selectedClassAttSem)
    const classRoomData = {
        classBooking : "classBooking",
        seminarId : selectedClassAttSem?.seminarId,
        seminarTopic : selectedClassAttSem?.seminarTopic,
        seminarDate : selectedClassAttSem?.seminarDate?.slice(0,11),
        seminarTime : selectedClassAttSem?.seminarTime?.slice(11,31),
        toDate : selectedClassAttSem?.remarks.slice(0,11),
        toTime : selectedClassAttSem?.remarks.slice(11,31),
        seminarFee : selectedClassAttSem?.seminarFee,
    }
    const allData = {classRoomData:classRoomData,useData:SelectedUser}
    commonLib.setDatatoSession("classVenue",JSON.stringify(allData))
    classRoomRegistration(
        attendeeObj?.seminarAttendeeUserId,
        attendeeObj?.seminarAttendeeId
        ,attendeeObj?.seminarId,
         selectedClassAttSem?.seminarId,
        attendeeObj?.seminarAttendeeUserId)
    if (selectedClassAttSem.noOfSeats != selectedClassAttSem.noOfRegisteredSeats) {
        setclassRoomDataObj(allData)
setshowPayment(true)
setLoader(false)
        // history.push({
        //     pathname : '/classroomvanupayment',
        //     state: allData
        // })
    }
    else{
        alert("No Seats Available")
    }

    // if(classRoomStatus === true){
    //     history.push({
    //         pathname : '/newClassroomRegistration',
    //         state: allData.classRoomData
    //        })
    // }
    // else{
    //     history.push({
    //         pathname : '/classroomvanupayment',
    //         state: allData
    //        })

    // }

   
   }
   const data1 =(seminar,toDate)=>{
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(seminar);
    var dayName = days[d.getDay()];
    var d2 = new Date(toDate);
    var dayName2 = days[d2.getDay()];
    return "("+dayName+"-"+dayName2+")"
     }

    return (


        <>
            <LayoutComponent>
                <Row style={{}} className="classroomregistration-main">
                    <Col span={[23]} className='ps-3 pt-2 mobileCol col-xs-12'>
                        <Commonregcomponent text="Select a Workshop" topic="Enjoy your retired life"/>
                        {error === "" ? (
                        <Row span={24} className="ps-4 pb-2 bg-white" >
                        <Col span={24}>
                          <Row className='mt-2 '>
                                <Col span={24} className="row d-flex justify-content-start gap-2 flex-row flex-nowrap overflow-auto Card-Col vertical-scroll mobileCard">

                                {/* {seminarList.map((classData,index)=>{ */}
                                    {seminarList.length > 0 && seminarList.map((classData, index) => {
                                          const toDate = classData.remarks.slice(0,11);
                                          const toTime = classData.remarks.slice(11,31);
                                          const weekDay  = classData.remarks.slice(20,40)
                                          konsole.log("date",toDate)
                                      return(
                                    <Card className='m-0 p-0 Card-Style mb-1'>
                                        <div className='p-1' >

                                            <Card.Img className='imageHd' variant="top" src="cardImage.png" />
                                        </div>
                                        <Card.Body className='m-0 pt-1 ps-1 px-1' >
                                            {/* <Link text="seminarList"> */}
                                                <div className='cursor-pointer' >
                                                    <h6 className='fw-bold profTopic-Tag-H6 text-left' key={index} onClick={()=>handleClassAttSelect(index)} >{classData?.seminarTopic}<span className='profTopic-Tag-span'>&nbsp;({classData?.seminarType})</span></h6>
                                                </div>
                                            {/* </Link> */}
                                            {/* <Card.Title style={{fontsize:"16px"}}>Enjoy Your Retired Life </Card.Title><span>(Inperson)</span> */}
                                            <Card.Text className='mb-5'>
                                                <div className='text-left'>
                                                    <h6 className='by-tag-h6' >
                                                    <div className='fw-bold'>By : <span  className='profName-tag-span'>{classData?.speakers[0]?.fName} {classData?.speakers[0]?.lName}</span></div>


                                                    </h6>
                                                    <h6 className='common-tag-h6' >
                                                  <div className='fw-bold'>Date : <span style={{fontWeight:500}}>{commonLib.getFormattedDate(classData?.seminarDate)} To {commonLib.getFormattedDate(toDate)} {weekDay == 1 ? "(Sat-Sun)":data1(classData?.seminarDate,toDate,weekDay)}</span>
                                                  </div>
                                                    </h6>
                                                    <h6 className='common-tag-h6'>
                                                    <div className='fw-bold'>Time : <span style={{fontWeight:500}}>{commonLib.getFormattedTime(classData?.seminarTime)} To {commonLib.getFormattedTime(toTime)}</span></div>
                                                    </h6>
                                                    <h6 className='common-tag-h6'>
                                                    <div className='fw-bold'>Fee : <span style={{fontWeight:500}}>${classData?.seminarFee}</span></div>
                                                    </h6>
                                                    <div className='d-flex justify-content-start' >
                                                        <h6 className='common-tag-h6'>
                                                        <div className='fw-bold'>Location : <span style={{fontWeight:500}}>{classData?.venueAddressLine1}</span></div> 
                                                        </h6>
                                                    </div>

                                                </div>
                                            </Card.Text>
                                            <div className='book-btn d-flex justify-content-center mb-2' >
                                                {/* <Link> */}
                                                    <button type="button" className="btn outline fw-bold BookNow-Button"  key = {index} onClick={()=>handleClassAttToPay(index,classData)}  >Book Now</button>
                                                {/* </Link> */}
                                            </div>
                                            
                                        </Card.Body>
                                    </Card>)
                                })}
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                        ):(
                            <div className='contenArea container-fluid  d-flex justify-content-center bgclr' style={{height: 'auto',flexDirection:"column", marginTop:"10%"}}>
                                                       <h3 className='text-center CongratulationHeading fw-bold '>No Workshop Available </h3>
                        </div>

                        )
                    }
                    {
          showPayment ?   <Payment
        //    paymentMethod={paymentMethod}
            // classData = {newRegisterUserDat === true ?classNewUserData:state}
            classData = {classRoomDataObj}
             attendeeObj={attendeeObj}
             />:''
        }
                        
                    </Col>
                </Row>
            </LayoutComponent>
        </>

    )
}

export default Classroomregistration
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../App';
import commonLib from '../../../control/commonLib';
import { selfCheckingCheckedIn, selfCheckInId } from '../../../control/Constant';
import konsole from '../../../network/konsole';
import Services from '../../../network/Services';
import Toaster from '../../../Toaster/Toaster';
import LayoutComponent from '../../LayoutComponent';
import QrCode from '../../QrCodeScanner';
import './index.css';



export default function SelfChecking(props) {
  const { setLoader } = useContext(UserContext);
  const history = useHistory();
  let seminarObj = commonLib.getObjFromStorage('seminarObj');
  const venueObj = commonLib.getObjFromStorage('venueObj');
  const attendeeObj = commonLib.getObjFromStorage('attendeeObj');
  const data1 = commonLib.getObjFromStorage('venueObj2');
  const [checkinField, setCheckInField] = useState('');
  const [error, setError] = useState(false);
  const [seminarData, setSeminarData] = useState();
  

//  konsole.log("dcc1",seminarObj)

useEffect(()=>{
  let seminarId = commonLib.getObjFromStorage('seminarObj')?.seminarId
  let promise = Services.getSeminarPublisher1(seminarId, true)
  promise.then((res)=>{
    konsole.log("getSeminarPublisher1Res", res)
    setSeminarData(res.data.data[0])
  }).catch((error)=>{
    konsole.log("getSeminarPublisher1err", error)
  }).finally(()=>{
    konsole.log("getSeminarPublisher1End")
  })
},[])



  const handleChange = (event) => {
    setError(false);
    setCheckInField(event.target.value)
  }

  const checkinStatusSubmit = (event) => {
    
    event.preventDefault();
    const attendeeMobileNo = attendeeObj?.attendeeMobileNo;
    const attendeeEmailAddress = attendeeObj?.attendeeEmailAddress;
    const subtenantId = seminarObj?.seminarSubtenantId;
    const seminarId = seminarObj?.seminarId;
    validateSeminarAttendee(subtenantId, seminarId, checkinField, selfCheckingCheckedIn);

  }


  const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy) => {
    setLoader(true);
    const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
    promises.then(res => {
      setLoader(false);
      konsole.log('registered Checkin res', res);
      commonLib.setDatatoSession("GetResponse",JSON.stringify(res.data.data))
      const selfWalkInCheckInObj = res.data.data?.attendees[0];
      
      const bookedSeats = {sheets:selfWalkInCheckInObj?.allottedSeats};
      history.push({
        pathname: '/Congratulations',
        state: bookedSeats
      })
    })
      .catch(err => {
        konsole.log("errs", err.response);
        Toaster.error(err.response.data?.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  const validateSeminarAttendee = (subtenantId, seminarId, contact, status) => {
    setLoader(true);
    const promises = Services.validateSeminarAttendee(subtenantId, seminarId, contact, status);
    promises.then(res => {
      setLoader(false);
      konsole.log("responsvie",res);
      const validateUserResponse = res.data.data[0];
      const attendeeSeminarId = validateUserResponse?.seminarAttendeeId;
      const attendeeUserId = validateUserResponse?.seminarAttendeeUserId;
      const attendeeObj = commonLib?.getSeminarAttendee(validateUserResponse);
      konsole.log("attendeeObj", attendeeObj);
      commonLib.setSessionStoarge('attendeeObj',JSON.stringify(attendeeObj));
      handleWalkInCheckInUser(seminarId, attendeeSeminarId, attendeeUserId, selfCheckInId, attendeeUserId);
    })
      .catch(err => {
        konsole.log("errd", err.response);
        Toaster.error(err.response.data?.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }




  const upsertSeminarAttendee = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, userGuestsNos, leadFrom, upsertedBy, isActive) => {
    setLoader(true);
    const promise = Services.upsertSeminarAttendee(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, userGuestsNos, leadFrom, upsertedBy, isActive);
    promise.then((res) => {
      setLoader(false);
      konsole.log("checkedIn res", res.data);
    }).catch((err) => {
      konsole.log(`error %%`, err.response)
    }).finally(fin => {
      setLoader(false);
      konsole.log("finish");
    })
  }

  
  return (
    <>
    <LayoutComponent>
      <div className='container-fluid bg-light self-checking-main'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-6 Inner-one'>
            <div className='p-2'>

              <h4 className='mb-1'>Seminar :</h4>
              <h1 className='fw-bold mb-1'>{seminarObj?.seminarTopic}</h1>
                <p className="fs-5">On {commonLib?.getFormattedDate(seminarObj?.seminarDate)} {(seminarObj?.seminarTime !== null) ?`at ${commonLib?.getFormattedTime(seminarObj?.seminarTime)}`: ''} <br />
                  Venue : {venueObj?.venueText !== undefined ? venueObj?.venueText : ""}<br/>

                Location : {venueObj?.venueAddressLine1}</p>
              <form id='selfCheckingForm'>
                <dl>
                  <dt className="fs-5 mb-2 fw-100 " style={{color:"#820024"}}>Mobile Number or Email Id</dt>
                  <dd className=''>
                    <input type="text" className="btn-check-index border-0 p-3" placeholder='+1 (102) 009 8500 or ***ple@gmail.com' onChange={(event)=> handleChange(event)} value={checkinField}/>
                  <p className='text-danger text-bold mt-2 ms-2'> { error && 'Entered Mobile No or Email Id does not match'}</p>
                  </dd>
                  <button className="btn-check-indexOne border-0 p-2 mb-2 text-white fs-5 p-3 " onClick={checkinStatusSubmit}>  Check-In</button>
                  <div className='setOR'>   
                  
                  </div>
                  {seminarObj?.subtenantId ?
               <button className='btn-check-indexSecond text-white border-0 mb-2 mt-1 fs-5 p-3 ' onClick={() => props.history.push({ pathname: '/Register', state: { seminarobj: seminarData,input:true} })}>New Registration</button> 
:""

                    }
                </dl>
              </form>
            </div>
          </div>
          <div className='col-sm-6 Inner-two  d-none d-md-block'>
            <QrCode />
          </div>
        </div>
      </div>
      </LayoutComponent>
    </>
  );
};

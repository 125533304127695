import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import commonLib from '../../../control/commonLib';
import konsole from '../../../network/konsole';
import LayoutComponent from '../../LayoutComponent';
import Services from '../../../network/Services';
import './Congratulations.css';
import { selfCheckingCheckedIn, selfCheckInId,leadGetApi,leadCommonApi, isNotValidNullUndefinedfile} from "../../../control/Constant"
import occurrenceId from '../../../control/occurrence';
import { message } from 'antd';
import Payment from '../../SelfPlanner/VenuePayment/Payment';

const Congratulations = (props) => {
    const history = useHistory();
    const [showPayment, setshowPayment] = useState(false)
    const bookedSeats = (props.location.state !== undefined) ? props.location.state : [];
    const seminarObj = commonLib.getObjFromStorage('seminarObj');
    const venueObj = commonLib.getObjFromStorage('venueObj');
    const user= commonLib.getObjFromStorage('user');
    const venueObj2 = commonLib.getObjFromStorage('venueObj2');
    const attendeeObj = commonLib.getObjFromStorage('attendeeObj');
    const userData = commonLib.getObjFromStorage('user');
    const subtenantId1 = commonLib.getObjFromStorage('subtenantId');
    const OrderId = props.location.search;
    const paymentStatus = commonLib.getQueryParameters(OrderId, "TrxnStatus")
    const toDate =  seminarObj?.remarks?.slice(0, 11);
  const toTime =  seminarObj?.remarks?.slice(11, 31);
  const weekDay = seminarObj?.remarks?.slice(20, 40)
  
  
  
  const data1 = (seminar, toDate) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(seminar);
    var dayName = days[d.getDay()];
    var d2 = new Date(toDate);
    var dayName2 = days[d2.getDay()];
    return "(" + dayName + "-" + dayName2 + ")"
}
    // konsole.log("order", paymentStatus)
    // konsole.log("rrr", selfCheckingCheckedIn)
    // sheets

    konsole.log("bookedSeatsbookedSeats",bookedSeats?.sheets,seminarObj,venueObj,bookedSeats,paymentStatus)

 konsole.log("subtenantId1",subtenantId1)

    // konsole.log("seminar1", seminarObj)
    // konsole.log("bookseats", bookedSeats);
    // konsole.log("props", props)

    konsole.log("paymentStatus", paymentStatus, props);

    const getSendCheckInLinktoUser = () => {
        // konsole.log("functionseinarid",semId)
        let subtenantId = sessionStorage.getItem('subtenantId')
        const semId = commonLib.getObjFromStorage("seminarObjH").seminarAttendeeId;
        let promise = Services.getSendCheckInLinktoUser(semId, 1);
        promise.then(res => {
            // konsole.log("SeminarId1233", res);
            konsole.log("SeminarId1233", res);

            occurrenceId.callOccurrenceIdFunc(1, subtenantId)
            let coordinatorEmailIData = commonLib.getObjFromStorage('user')?.semObj
          callSeminarAllCoordData(coordinatorEmailIData?.seminarId)
            // sessionStorage.setItem("seminarFullData", coordData)
            // commonLib.setDatatoSession("seminarFullData", coordData22222)

            //     konsole.log("coordinatorEmailIData1111",coordData22222)
            //     for(let i=0; i <coordData22222?.coordinators.length; i++){
                    
            //         occurrenceId.callOccurrenceIdFunc(10,subtenantId, i)
            //     }

            // occurrenceId.callOccurrenceIdFunc(10,subtenantId)
        }).catch((error)=>{
            konsole.log("SeminarId1233error",error);

        }).finally(()=>{

            konsole.log("SeminarId1233End");
        })
    }

    const callSeminarAllCoordData = (seminarId,) =>{
        let subtenantId = sessionStorage.getItem('subtenantId')
        
        let promise = Services.getSeminarPublisher1(seminarId, true)
        promise.then((res)=>{
            konsole.log("getSeminarPublisher1res",res.data.data[0])
            commonLib.setDatatoSession("seminarFullData", JSON.stringify(res.data.data[0]))
            let coordataFull = res.data.data[0]?.coordinators
            for(let i=0; i <coordataFull.length; i++){
                        
                occurrenceId.callOccurrenceIdFunc(10,subtenantId, i)
            }


        }).catch((err)=>{
            konsole.log("getSeminarPublisher1err",err)
        }).finally(()=>{
            konsole.log("getSeminarPublisher1end")
        })
    }


    useEffect(()=>{

        let subtenantIDNew = !isNotValidNullUndefinedfile(subtenantId1) ? seminarObj?.subtenantId : subtenantId1

        if ( paymentStatus !== null &&  paymentStatus == "SUCCESS") {
            getSendCheckInLinktoUser();
            // console.log("sakkkkkkkkk",user?.post)
            // let tags = [`notattended,${user?.post?.firstName} ${user?.post?.lastName}`,`${user?.semObj?.seminarTopic}`]
            let tags = ['registered','not attended',`${user?.semObj?.seminarTopic}`]
            getLoggedInUserdata(user?.post?.emailAddress,user?.post?.firstName,"POST",tags,user?.post)
            
        }else if(paymentStatus == "CANCELLED"){
            history.push({
                pathname: `/EventListing/${subtenantIDNew}`,
            })
            // message.error("Payment Failed")
        }else{
            let subtenantId = sessionStorage.getItem('subtenantId')
            if(bookedSeats?.sheets !== undefined && bookedSeats?.sheets !== null && bookedSeats?.sheets !== ""){

                // occurrenceId.callOccurrenceIdFunc(2, subtenantId)
            }else{
                occurrenceId.callOccurrenceIdFunc(1, subtenantId)
                // let coordinatorEmailIData = commonLib.getObjFromStorage('user')?.semObj?.coordinators
                // konsole.log("coordinatorEmailIData",coordinatorEmailIData)
                // for(let i=0; i <coordinatorEmailIData.length; i++){
                    
                //     occurrenceId.callOccurrenceIdFunc(10,subtenantId, i)
                // }
                let coordinatorEmailIData = commonLib.getObjFromStorage('user')?.semObj
                 callSeminarAllCoordData(coordinatorEmailIData?.seminarId)
                // sessionStorage.setItem("seminarFullData", coordData)
                // commonLib.setDatatoSession("seminarFullData", JSON.stringify(coordData22222))
    
                 
                    
            }

        }
         },[])


    useEffect(() => {
        if (paymentStatus == null && bookedSeats.sheets !== undefined ) {
            setTimeout(
                (user) => {
                    history.replace("/selfChecking")
                },
                5000
            );
        }
    }, [])

     useEffect(() => {
       let subtenantIDNew = !isNotValidNullUndefinedfile(subtenantId1) ? seminarObj?.subtenantId : subtenantId1
        setTimeout(() => {
            history.push({
                pathname: `/EventListing/${subtenantIDNew}`,
             
            })
         
          }, 5000)
    }, [])
    const getLoggedInUserdata = async (email,name,method,tag,json2) => {
        let json = {
                 email : email,
                 tag : tag
             } 
          
        const result = await leadGetApi(json,json2);
        // console.log("sampleee",result)
          if(result !== null && result !== undefined && result !== ""){
            const result2 = await leadCommonApi(result,method)
          }
        if(result=='err') return;
    };


    

    const tryAgain = () => {
        setshowPayment(true)
        // history.push({
        //     pathname: '/VenuePayment',
        //     state: userData
        // })
    }
//   
konsole.log("samehere",paymentStatus == null && bookedSeats?.sheets !== undefined)
    return (
        <LayoutComponent>
            <div>
                {
                (paymentStatus !== null && paymentStatus == "SUCCESS" && (seminarObj?.isSeminarPaid === true || seminarObj?.isSeminarPaid === false) && bookedSeats?.sheets == undefined) ?
                    <div className='' style={{ backgroundColor: '#f6f6f6' }} >
                        <div className="container-fluid">
                            <div className='pb-4 d-flex justify-content-between flex-column flex-md-row'>
                                <div className='mt-4 fontCongratulationMain'>
                                    <h4>{subtenantId1 == 745 ? "Workshop" : "Seminar"} : {(bookedSeats?.sheets) ? "Seminar Check-In" : "New Registration"}</h4>   
                                    <p className='fs-5'>Topic : {seminarObj.seminarTopic}</p>
                                </div>
                                <div className='mt-4'>
                                {
                                    (seminarObj?.seminarType === "Webinar")?
                                        <></>
                                        :
                                        <>
                                    <h5>Venue : {bookedSeats?.sheets ? venueObj.venueText : seminarObj.seminarVenue}</h5>
                                    <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }}>Location : {bookedSeats?.sheets ? venueObj.venueAddressLine1 : seminarObj.venueAddressLine1}</p>
                                        </>
                                }
                                    <p className="bi bi-calendar form-label fs-6" style={{ color: "#720c20" }}>
                                        {subtenantId1 == 745 ? (`${commonLib.getFormattedDate(seminarObj?.seminarDate)} To ${commonLib.getFormattedDate(toDate)} ${weekDay == 1 ? "(Sat-Sun)" : data1(seminarObj?.seminarDate, toDate, weekDay)} At ${commonLib.getFormattedTime(seminarObj?.seminarTime)} To ${commonLib.getFormattedTime(toTime)} `)  :
                                        `${commonLib.getFormattedDate(seminarObj?.seminarDate)} ${" "} ${seminarObj?.seminarTime === null ? "" : commonLib.getFormattedTime(seminarObj?.seminarTime)}`
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-5 bg-white col-10 col-md-10  m-auto rounded" >
                            <h1 className='fs-1 text-center CongratulationHeading'>Congratulations</h1>
                            {bookedSeats?.sheets ? <h3 className='text-center'>You are successfully checked-in. Please view your seat details below.</h3> : <h3 className='text-center'>You have  Registered Successfully</h3>}

                            {/* <p className='pb-5 fs-5'>Details has been sent to your registered mail ID</p> */}
                            {bookedSeats?.sheets ?

                                <div className='row mt-5 mt-5'>
                                    <div className='col-8 sol-md-8 col-lg-7 m-auto Congratulation-box d-flex flex-column flex-md-row align-items-center justify-content-center gap-3 py-2 py-sm-0'>
                                        <div className=""><img src="/icons/congratulationsLogo.svg" /></div>
                                        <div className=" fs-3 text-center">Your seats are: </div>
                                        <div className="vatical-line d-none d-md-block"></div>
                                        <div className="seatbelowLine d-block d-md-none"></div>
                                        <div className="fs-2 ms-3">
                                            No :

                                            {bookedSeats.sheets !== undefined && bookedSeats?.sheets.map((seat, index) => {
                                                return `${seat.seatNo}${(bookedSeats.sheets.length - 1 === index) ? '' : ', '}`
                                            })}
                                        </div>
                                    </div>
                                </div>

                                : ""

                            }

                        </div>
                    </div>
                    :
                        ((paymentStatus !== null && (paymentStatus == "FAILED" || paymentStatus == "CANCELLED") && (seminarObj?.isSeminarPaid === true || seminarObj?.isSeminarPaid === false) && bookedSeats?.sheets == undefined) )?
                    <div className='container-fluid pb-3' >


                        <div className='pb-4 d-flex justify-content-between flex-column flex-md-row'>
                            <div className='mt-4' >
                                <h4>Seminar : {(bookedSeats?.sheets) ? "Seminar Check-In" : "New Registration"}</h4>                            <p className='fs-5'>Topic : {seminarObj.seminarTopic}</p>
                            </div>
                            <div className='mt-4'>
                                        <h5>Venue : {(bookedSeats?.sheets)? venueObj.venueText : seminarObj.seminarVenue}</h5>

                                <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }}>Location : {bookedSeats?.sheets ? venueObj.venueAddressLine1 : seminarObj.venueAddressLine1}</p>
                                <p className="bi bi-calendar form-label fs-6" style={{ color: "#720c20" }}>{commonLib.getFormattedDate(seminarObj?.seminarDate)} {" "} {seminarObj?.seminarTime === null ? "" : commonLib.getFormattedTime(seminarObj?.seminarTime)}


                                </p>
                            </div>
                        </div>


                        <div className='container bg-white  mb-5'  >
                            <div className='text-center '>
                                <img className='mt-5' src='\imgpsh_fullsize_anim.png'></img>
                            </div>
                            <h1 className=' congratulationNew text-center  mt-2 fw-bold fs-1 pt-3 ' style={{color:"#F2494B"}}>Your Payment is Failed</h1>



                            <div className='btm text-center pb-4  mb-5'>
                                <button type='text' className='cnt-bttn border-0 rounded text-center p-2 mt-2 mb-5' onClick={()=>tryAgain()}>
                                    Try Again
                                </button>
                            </div>

                        </div>
                    </div>
                    :
                    (paymentStatus == null && bookedSeats?.sheets !== undefined) ?
                    <div className='' style={{ backgroundColor: '#f6f6f6' }} >
                        <div className="container-fluid">
                            <div className='pb-4 d-flex justify-content-between flex-column flex-md-row'>
                                <div className='mt-4' >
                                    <h4>Seminar : {(bookedSeats?.sheets) ? "Seminar Check-In" : "New Registration"}</h4>                            <p className='fs-5'>Topic : {seminarObj.seminarTopic}</p>
                                </div>
                                <div className='mt-4'>
                                    {
                                        (seminarObj?.seminarType === "Webinar") ?
                                            <></>
                                            :
                                            <>
                                                <h5>Venue : {venueObj.venueText }</h5>
                                                <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }}>Location : {bookedSeats?.sheets ? venueObj.venueAddressLine1 : seminarObj.venueAddressLine1}</p>
                                            </>
                                    }
                                    <p className="bi bi-calendar form-label fs-6" style={{ color: "#720c20" }}>{commonLib.getFormattedDate(seminarObj?.seminarDate)} {" "} {seminarObj?.seminarTime === null ? "" : commonLib.getFormattedTime(seminarObj?.seminarTime)}


                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="p-5 bg-white col-10 col-md-10  m-auto rounded" >
                            <h1 className='fs-1 text-center CongratulationHeading'>Congratulations</h1>
                            {bookedSeats?.sheets ? <h3 className='text-center'>You are successfully checked-in.</h3> : <h3 className='text-center'>You have Registered Successfully</h3>}

                            {/* <p className='pb-5 fs-5'>Details has been sent to your registered mail ID</p> */}
                            {/* {bookedSeats?.sheets ?

                                <div className='row mt-5 mt-5'>
                                    <div className='col-8 sol-md-8 col-lg-7 m-auto Congratulation-box d-flex flex-column flex-md-row align-items-center justify-content-center gap-3 py-2 py-sm-0'>
                                        <div className=""><img src="/icons/congratulationsLogo.svg" /></div>
                                        <div className=" fs-3 text-center">Your seats are: </div>
                                        <div className="vatical-line d-none d-md-block"></div>
                                        <div className="seatbelowLine d-block d-md-none"></div>
                                        <div className="fs-2 ms-3">
                                            No :

                                            {bookedSeats.sheets !== undefined && bookedSeats?.sheets.map((seat, index) => {
                                                return `${seat.seatNo}${(bookedSeats.sheets.length - 1 === index) ? '' : ', '}`
                                            })}
                                        </div>
                                    </div>

                                </div>
                                : <></>

                            }  */}

                        </div>
                    </div>
                    : <>
                     <div className='' style={{ backgroundColor: '#f6f6f6' }} >
                        <div className="container-fluid">
                            <div className='pb-4 d-flex justify-content-between flex-column flex-md-row'>
                                <div className='mt-4' >
                                    <h4>Seminar : {(bookedSeats?.sheets) ? "Seminar Check-In" : "New Registration"}</h4>
                                    <p className='fs-5'>Topic : {seminarObj?.seminarTopic}</p>
                                </div>
                                <div className='mt-4'>
                                    {
                                        (seminarObj?.seminarType === "Webinar") ?
                                            <></>
                                            :
                                            <>
                                                <h5>Venue : {seminarObj?.seminarVenue}</h5>
                                                <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }}>Location : {bookedSeats?.sheets ? venueObj?.venueAddressLine1 : seminarObj?.venueAddressLine1}</p>
                                            </>
                                    }
                                    <p className="bi bi-calendar form-label fs-6" style={{ color: "#720c20" }}>{commonLib.getFormattedDate(seminarObj?.seminarDate)} {" "} {seminarObj?.seminarTime === null ? "" : commonLib.getFormattedTime(seminarObj?.seminarTime)}


                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="p-5 bg-white col-10 col-md-10  m-auto rounded" >
                            <h1 className='fs-1 text-center CongratulationHeading'>Congratulations</h1>
                            <h3 className='text-center'>You have  Registered Successfully</h3>
                            

                        </div>
                    </div>
                    
                    
                    </>
                }
            </div>
            {showPayment ? (
          <Payment
            selectedUser={userData}
            // orderId={orderId}
            // paymentMethod={paymentMethod}
            attendeeObj={attendeeObj}
            seminarObj={seminarObj}
          />
        ) : (
          ""
        )}

        </LayoutComponent>
    );
}

export default Congratulations;
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form'
import commonLib from '../../../control/commonLib';
import { selfWalkInId, selfCheckInId, EnquiryRegistrationId, CounterCheckInId,ConstantEmail,ConstantText,leadGetApi,leadCommonApi,isNotValidNullUndefinedfile} from '../../../control/Constant';
import konsole from '../../../network/konsole';
import Services from '../../../network/Services';
import './Registration.css';
import { useHistory } from 'react-router-dom';
import Toaster from '../../../Toaster/Toaster';
import LayoutComponent from '../../LayoutComponent';
import { UserContext } from '../../../App';
import CongratsComponent from '../../CongratsForm/CongratsComponent';
import PhoneNumberMasking from "./PhoneNumberMask"
import Address from './Address';
import Payment from '../../SelfPlanner/VenuePayment/Payment';



const Registration = (props) => {
  const { setLoader } = useContext(UserContext);
  const history = useHistory();
  const[addressreg,setaddressreg]=useState('')
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [contactnum, setcontactnum] = useState();
  const venueObj = commonLib.getObjFromStorage('venueObj');
  const attendeeObj = commonLib.getObjFromStorage('attendeeObj');  
  const getseminarObjdata = commonLib.getObjFromStorage("seminarObj");
  const subtenantIdKay = commonLib.getObjFromStorage("subtenantId");
  
  const [attendees, setattendees] = useState()
  const [usersData, setUsersData] = useState("")
   const seminarObj = props.location.state.seminarobj;
  const seminarObjct = (props.location.state !== undefined) ? props.location.state : [{}];
  const semObj = props.location.state.seminarobj;
  const [phonemsg, setphonemsg] = useState("")
  const [referenceModeList, setReferenceMode] = useState([]);
  const [registeredUser, setRegisteredUser] = useState(true);
  const [clientIpAddress, setClientIpAddress] = useState('');
  const [paymentPage, setPaymentPage] = useState(false)
  const toDate =  seminarObj?.remarks?.slice(0, 11);
  const toTime =  seminarObj?.remarks?.slice(11, 31);
  const weekDay = seminarObj?.remarks?.slice(20, 40)
  
  
  
  const data1 = (seminar, toDate) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(seminar);
    var dayName = days[d.getDay()];
    var d2 = new Date(toDate);
    var dayName2 = days[d2.getDay()];
    return "(" + dayName + "-" + dayName2 + ")"
}



  useEffect(() => {
    getIpAddress();
    getCountryCodeList();
    getReferenceMode();
  }, [])

  const getIpAddress = () => {
    let promise = Services.getIpAddress();
    promise.then(res => {
      konsole.log("IP address78", res);
      setClientIpAddress(res.IPv4);
    })
  }

  const getReferenceMode = () => {
    let promise = Services.getReferenceMode();
    promise.then(res => {
      konsole.log("reference mode", res);
      setReferenceMode(res.data.data)
    })
  }

  const getCountryCodeList = () => {
    const promises = Services.getCountryCodeList();
    promises.then(res => {
      konsole.log('countryCodeList', res);
      setCountryCodeList(res.data.data)
    })
      .catch(err => {
        konsole.log("err", err);
      }).finally(fin => {
        konsole.log("finish");
      })
  }

  const { register, handleSubmit, setError, watch, setValue, clearErrors, formState: { errors } } = useForm();
  
  const onSubmit = data => {
    const twoDigitRandomNumber = Math.floor(Math.random() * (999 - 100)) + 10;

    let userRegistration = data;
    userRegistration['roleId'] = 2;
    userRegistration['noOfAttendee'] = parseInt(userRegistration.noOfAttendee);
    userRegistration['referenceMode'] = parseInt(userRegistration.referenceMode);
    userRegistration['firstName'] = userRegistration?.firstName?.charAt(0)?.toUpperCase()+ userRegistration?.firstName?.slice(1);
    userRegistration['lastName'] = userRegistration?.lastName?.charAt(0)?.toUpperCase()+ userRegistration?.lastName?.slice(1);
    userRegistration['clientIPAddress'] = clientIpAddress;
    userRegistration['countryCode'] = '+1';
    userRegistration['semUserStatusId'] = EnquiryRegistrationId;
    userRegistration['emailAddress'] = !isNotValidNullUndefinedfile(userRegistration?.emailAddress) ?
   `${userRegistration?.firstName}${twoDigitRandomNumber}${ConstantEmail}`: userRegistration?.emailAddress;
    userRegistration['phoneNumber'] = !isNotValidNullUndefinedfile(userRegistration?.phoneNumber) ? ConstantText : userRegistration?.phoneNumber


    const noOfRegisteredSeats = seminarObj.noOfRegisteredSeats;
    const noOfSeats = seminarObj.noOfSeats;
    
    if (userRegistration.noOfAttendee > (noOfSeats - noOfRegisteredSeats)) {
      Toaster.info(`Only ${noOfSeats - noOfRegisteredSeats} Seats remaining`);
      return;
    }
      if(userRegistration?.phoneNumber.length == 10){
        registerNewUser(semObj.subtenantId, semObj.seminarId, userRegistration);
      }
     
    
   

  }




  const registerNewUser = (subtenantId, seminarId, userRegistration) => {
    setLoader(true)
    const promise = Services.registerNewUser(subtenantId, seminarId, userRegistration)
    konsole.log('register user ', subtenantId, seminarId, userRegistration);
    const post = userRegistration;

    promise.then(res => {
      konsole.log("user",res)
      const registeredUserResponse = res.data.data;
      const seminarId = registeredUserResponse.seminarId;
      const subtenantId = registeredUserResponse.subtenantId;
      const seminarAttendeeId = registeredUserResponse.seminarAttendeeId;
      const attendeeUserId = registeredUserResponse.userId;


      let jsondata={...getseminarObjdata,...semObj}
      commonLib.setDatatoSession("seminarObj", JSON.stringify(jsondata))
      commonLib.setDatatoSession("seminarObjH", JSON.stringify(res.data.data))
      
      setRegisteredUser(false);

      const post2 = res.data.data;
      const userData = { semObj: semObj, post: post, post2: post2 ,seminarObjct : seminarObjct }
      konsole.log("alltheDatatta",semObj,post,post2,seminarObjct)
      commonLib.setDatatoSession("user", JSON.stringify(userData))


      if(addressreg !=='' && addressreg !==undefined && addressreg !==null){
        let jsonforaddress={
          "userId":res.data.data.userId,
          "address": {
            "lattitude": "",
            "longitude": "",
            "addressLine1":addressreg?.address,
            "addressLine2": "",
            "addressLine3": "",
            "zipcode": addressreg?.zipcode,
            "city": addressreg?.city,
            "state": addressreg?.state,
            "country": addressreg?.country,
            "addressTypeId": 1,
            "createdBy": res.data.data.userId
          }
        }

        let promise=Services.postaddressbyuserid(jsonforaddress)
        promise.then((res)=>{
          konsole.log("resressaddress",res)
          afternewregresponse(seminarObj,post2,seminarAttendeeId,attendeeUserId,userData,userRegistration)

        }).catch((err)=>{
          konsole.log("errr",err)
        })

      }else{
        afternewregresponse(seminarObj,post2,seminarAttendeeId,attendeeUserId,userData,userRegistration)

      }
 })
      .catch(err => {
        konsole.log("err111", err);
        setLoader(false)
        Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish", fin);
      })
  }

  const afternewregresponse=(seminarObj,post2,seminarAttendeeId,attendeeUserId,userData,userRegistration)=>{
    konsole.log("paipaid",seminarObj?.isSeminarPaid,userData)
          if (seminarObj?.isSeminarPaid == true) {
            setLoader(false)
            setUsersData(userData)
            setPaymentPage(true)
            setTimeout(() => {
              setPaymentPage(false);
            }, 2000);
   
       
 }
      else{
        // let tags = [`notattended,${userRegistration?.firstName} ${userRegistration?.lastName}`,`${seminarObj?.seminarTopic}`]
        // let tags = [`notattended,${userRegistration?.firstName} ${userRegistration?.lastName}`]
        let tags = ['registered','not attended']
        getLoggedInUserdata(userRegistration?.emailAddress,"POST",tags,userRegistration)
       
        if(seminarObjct.input == true){
          let upsertedBy = "ae2d8905-5282-4862-be40-52652969baf2"
          // konsole.log("dssb",post2.seminarId, seminarAttendeeId, attendeeUserId, selfCheckInId, upsertedBy)
           handleWalkInCheckInUser(post2.seminarId, seminarAttendeeId, attendeeUserId, selfCheckInId, upsertedBy)
         
          }

      else{
        setLoader(false)
        history.push({
          pathname: '/Congratulations',
          state: userData
        })
      }
      }

  }

  
 const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy) => {
    setLoader(true);
    const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
    // konsole.log("ash", seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy)
    promises.then(res => {
      // konsole.log('responses1', res);
      setLoader(false);
      konsole.log('registered Checkin res', res);
      const selfWalkInCheckInObj = res.data.data.attendees[0];
      const bookedSeats = {sheets:selfWalkInCheckInObj.allottedSeats};
      // konsole.log("bookedSeats", selfWalkInCheckInObj.allottedSeats)

     history.push({
          pathname: '/Congratulations',
          state: bookedSeats
        })

    


    })
      .catch(err => {
        konsole.log("err1", err.response);
        setLoader(false)
        Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        setLoader(false);
        konsole.log("finish");
      })
  }

  const onchangeContact = (value) => {
    setcontactnum(value)
  };

 const registeroptions = {
    firstName: {
      required: "Please enter your first name",
      pattern: {
        value: /[A-Z a-z]/,
        message: "Please enter the valid first name"
      }
    },
    lastName: {
      // required: "Please enter your last name",
      pattern: {
        value: /[A-Z a-z]/,
        message: "Please enter the valid last name"
      }
    },
    emailAddress: {
      // required: "Please enter Email address",
      pattern: {
        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please enter valid Email addreess',
      }
    },
    phoneNumber: {
      required: "Please enter Mobile number",
      // maxLength:{
      //   value:10,
      //   message:"Please enter valid Mobile number"
      // },
      pattern: {
        value: /^([+]\d{2})?\d{10}$/,
        message: "Please enter valid  Mobile number"
      },
      validate: {
        ValueNull: (value) => value !== "",
        NumberExcess: (value) => value.length === 10
      }
    },
    noOfAttendee: {
      required: "Please enter No of attendence",
      pattern: {
        value: /^[1-9]/,
        message: "Only Digit Allow"
      },
      minLength: {
        vlaue: 1,
        message: "Please enter no of attendence"
      }

    },
    // leadFrom: {
    //   required: "Lead from is required*",
    //   pattern: {
    //     value: /[A-Z a-z]/,
    //     message: "Only Characters allow"
    //   }
    // },

  }



  const selectOptionsList = (value, label) => {
    konsole.log("value", label);
  }

  const back=()=>{
    history.goBack();
  }
  const referenceStatusId1 = (e) => {
    //  handleSubmit()
    //  register('noOfAttendee',registeroptions.noOfAttendee)
    setError("noOfAttendee","")
    konsole.log("referenceStatusIdreferenceStatusId", e.target.value)
    setattendees(e.target.value)

  }
  const getLoggedInUserdata = async (email,method,tag,json2,method1) => {
    let json = {
             email : email,
             tag : tag
         } 
        //  alert(method)
    const result = await leadGetApi(json,json2);
    if(isNotValidNullUndefinedfile(result)){
        const result2 = await leadCommonApi(result,method)
         return result2
      }
    if(result=='err') return;
};
  return (<div>  {(registeredUser == true) ?
    <>
      <LayoutComponent>
        <div className='container-fluid Registration-main'>

          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className=' mt-2 p-0 registration-seminar-small' >
              <h3 >{subtenantIdKay == 745 ? "Workshop" : "Seminar"}<b> : New Registration</b></h3>
              <p className='fs-5'>Topic : {semObj.seminarTopic}
                {/* {konsole.log("seminarObj11", seminarObj)} */}
                
                {
                  seminarObj.subtenantId !== 26 &&
                  <span classname="ms-2 " style={{ color: "black", fontSize: "15px" }}> ({seminarObj.seminarType},
                    {seminarObj.isSeminarPaid == true ? 'Paid' : 'Free'}
                    {seminarObj.isSeminarPaid == true ? " : " + "$" + seminarObj.seminarFee : ""})</span>
                }

                
              </p>
            </div>
            {/* <div className='col-2'></div> */}
            <div className='p-0 mt-2 registration-seminar-small'>
            {
               (seminarObj?.seminarType === "Webinar")?
                <></>
                :
                <>
              <h5>Venue : {venueObj?.venueAddressLine1 ? venueObj.venueAddressLine1: semObj?.seminarVenue}</h5>
              {/* <p className=" bi bi-geo-alt form-label fs-6" > {venueObj.venueAddressLine1}</p> */}
              <h5 className='bi bi-geo-alt form-label fs-6'style={{ color: "#720c20" }}>Location : {venueObj?.venueAddressLine1 ? venueObj.venueAddressLine1: semObj.venueAddressLine1}</h5>
              </>
            }
              <p className="bi bi-calendar form-label fs-6" style={{ color: "#720c20" }}>On {" "}{
                subtenantIdKay == 745 ? (`${commonLib.getFormattedDate(seminarObj?.seminarDate)} To ${commonLib.getFormattedDate(toDate)} ${weekDay == 1 ? "(Sat-Sun)" : data1(seminarObj?.seminarDate, toDate, weekDay)} At ${commonLib.getFormattedTime(seminarObj?.seminarTime)} To ${commonLib.getFormattedTime(toTime)} `) :
              (`${commonLib.getFormattedDate(seminarObj?.seminarDate)} ${" "} ${seminarObj?.seminarTime === null ? ""  : commonLib.getFormattedTime(seminarObj?.seminarTime)}`)
              } 
                </p>


            </div>

          </div>

          <form className='container-fluid  bg-white' onSubmit={onSubmit}>

            <div className='row  pt-3'>
              <div className='col-md-6  form-group'>
                <label for="formGroupExampleInput" className="form-label label-name-color fs-5">{commonLib.mandatory("First Name")} </label>
                <input type="text" className="form-control bg-light fs-6" id="firstName"
                  placeholder="Enter First Name" {...register("firstName", registeroptions.firstName)} style={{ textTransform: "capitalize" }}></input>
                {/* {errfirstName ? <span className="text-dark">Only Character Enter</span> : null} */}
                {/* {errors.firstName && <span className="RegistrationValidationErrPrint">First Name is Required</span>} */}

                {<p className="ps-3 text-danger">{errors?.firstName && errors.firstName.message}</p>}
              </div>

              <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput" className="form-label label-name-color  fs-5">Last Name</label>
                <input type="text" className="form-control bg-light fs-6" id="lastName" placeholder=" Enter Last Name" {...register('lastName', registeroptions.lastName)} style={{ textTransform: "capitalize" }}></input>

                {<p className="ps-3 text-danger">{errors?.lastName && errors.lastName.message}</p>}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <label for="formGroupExampleInput2" className="form-label label-name-color  fs-5 ">Cell Number</label>
                <div className='row gap-4 gap-md-0'>
                  {/* <div className='col-md-3 m-0'>
                    <select className=" form-select p-1 bg-light fs-7 selectpicker bg-light dropdown-item  border  fs-6" {...register('countryCode', { required: true })} placeholder="Code">
                      {
                        countryCodeList.map((code, index) => {
                          return <option value={code.value}>{code.label}</option>
                        })
                      }
                    </select>
                  </div> */}
                  <div className='col-md-12 m-0'>
                  <PhoneNumberMasking id ="phoneNumber"className="form-control bg-light fs-6"  setValue={setValue} setError={setError} setClearError={clearErrors}onValueChange={onchangeContact}/>
                  {errors.phoneNo && errors.phoneNo?.type == "error" && (<p className='ps-3 text-danger'>{errors.phoneNo.message}</p>)}
                  <p className="ps-3 text-danger" >{phonemsg}</p>
                    {/* <input type="tel" className="form-control bg-light fs-6" id="phoneNumber" placeholder="Enter Phone Number"  {...register('phoneNumber', registeroptions.phoneNumber)} ></input> */}
                  </div>
                  {props.value?.length == 0 ? <p className=" text-danger">{props.errors?.phoneNumber && props.errors.phoneNumber.message}</p> : ""}
                {<p className="ps-3 text-danger">{contactnum?.length == 0 ? "" : contactnum?.length < 10 ? "Please enter the valid Cell Number" : ""}</p>}



                 </div>
                {/* {<p className="ps-3 text-danger">{errors?.phoneNumber && errors.phoneNumber.message}</p>}
                 */}
              </div>
              <div className='col-md-6 form-group' >
                <label for="formGroupExampleInput2" className="form-label label-name-color  fs-5 ">Email</label>
                <input type="text" className="form-control bg-light fs-6" id="emailId" placeholder="Enter Email"
                  {...register('emailAddress', registeroptions.emailAddress)} ></input>
                {<p className="ps-3 text-danger">{errors?.emailAddress && errors.emailAddress.message}</p>}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput2" className="form-label label-name-color fs-5">{commonLib.mandatory("No. of Attendees")}</label>
                {/* <input type="number" className="form-control bg-light fs-6" id="noOfAttendee" min={0} placeholder="Enter No. of Attendees"  {...register('noOfAttendee', registeroptions.noOfAttendee)}></input> */}
                <select id="uniqueDropdown" className=" form-select p-1 bg-light fs-7 selectpicker  bg-light dropdown-item  border  fs-6" {...register('noOfAttendee', registeroptions.noOfAttendee)} value={attendees} onChange={referenceStatusId1} placeholder="Select No. of Attendees">
                  <option value="" disabled selected>Select No. of Attendees</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  {/* {
                    referenceModeList.length > 0 && referenceModeList.map((reference, index) => {
                      return <option value={reference.value}>{reference.label}</option>
                    })
                  } */}
                </select>
                {<p className="ps-3 text-danger">{errors?.noOfAttendee && errors.noOfAttendee.message}</p>}
              </div>
              {/* <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput2" className="form-label label-name-color fs-5">Lead From</label>
                <input type="text" className="form-control bg-light fs-6" id="leadFrom" placeholder=" Enter Lead From" {...register('leadFrom', registeroptions.leadFrom)}></input>

                {<p className="ps-3 text-danger">{errors?.leadFrom && errors.leadFrom.message}</p>}
              </div> */}
              <div className='col-md-6 form-group'>
                <label for="formGroupExampleInput" className="form-label label-name-color fs-5" >How did you hear about us?</label>
                {
                  props.seminarObj?.subtenantId !== 26 &&
                  <select className=" form-select p-1 bg-light fs-7 selectpicker height-20px bg-light dropdown-item  border  fs-6" {...register('referenceMode')} placeholder="How did you hear about us?">
                    <option value="">How did you hear about us?</option>
                    {
                      referenceModeList.length > 0 && referenceModeList.map((reference, index) => {
                        return <option value={reference.value}>{reference.label}</option>
                      })
                    }
                  </select>
                }
                {errors.title && <span className="ps-3 label-name-color">Required*</span>}
              </div>
              
            </div>

            <div className='row mb-4'>
              <Address  setaddressreg={setaddressreg}/> 
            </div>
            
            
            {/* <div className='row mb-2'>
            </div> */}
          
            <div className='row pb-4 justify-content-end   mt-2' >
              <div className='col-6' >
                <button className='btn  text-white  w-50 p-2 mt-2 float-left rounded Registrationbuttonback' style={{ backgroundColor: '#720c20' }} type='button' id='btnBack' onClick={back} >Back </button>
              </div>
              <div className='col-6' >
                <button className='btn  text-white  w-50 p-2 mt-2 float-end rounded Registrationbuttonreg' style={{ backgroundColor: '#720c20' }} type='button' id='btnRegister' onClick={handleSubmit(onSubmit)} >Register Now </button>
              </div>
            </div>
          </form>
        </div>
      </LayoutComponent>
      {/* {konsole.log("btnId", props.callAPiFromPopup)} */}
    </>
    :
    ""
    // <CongratsComponent buttonId={props.buttonId}  callAPiFromPopup={props.callAPiFromPopup}/>

  }
  {
    paymentPage == true ? 
    <Payment
    selectedUser={usersData}
    // orderId={orderId}
    // paymentMethod={paymentMethod}
    attendeeObj={attendeeObj}
    seminarObj={seminarObj}
    /> : ""
  }
  </div>



  );
}

export default Registration;
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import commonLib from "../../../control/commonLib";
import occurrenceId from "../../../control/occurrence";
import konsole from "../../../network/konsole";
import Toaster from "../../../Toaster/Toaster";
import LayoutComponent from "../../LayoutComponent";
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import Payment from "./Payment";
import "./VenuePayment.css";
import EventHeader from "../../SeminarListingHeader";

export default function VenuePayment(props) {
  konsole.log("kuchh",props)
  const history = useHistory();
  // const selectedUser = (props.location.state !== undefined) ? props.location.state : {};
  const [status, setStatus] = useState(0); // 0: no show, 1: show yes, 2: show no.
  // const subtenantId = commonLib.getObjFromStorage("seminarObj").seminarSubtenantId;
  const [attendeeObjData,setAttendeeObjData] = useState("")
  const [seminarObjData, setSeminarObjData] = useState("")
  const [bool, setbool] = useState(false)
  const venueObj = commonLib.getObjFromStorage("venueObj");
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const data3 = commonLib.getObjFromStorage("venueObj2");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [orderId, setorderId] = useState("");
  const [price, setprice] = useState(null)
  const [showData, setshowData] = useState(false)
  const [showDatas, setshowDatas] = useState(false)
  const [discountedPrize, setdiscountedPrize] = useState(null)
  // const semObj = props.location.state.seminarobj;
  const bookedSeats =
    props.location.state !== undefined
      ? props.location.state
      : [{ seatNo: 101 }];
  konsole.log("addition", bookedSeats);

  const [showPayment, setshowPayment] = useState(false);
  konsole.log("seminarObjseminarObj", seminarObj);
  const handlePaymentMethod = (selectedPaymentMethod, method) => {
    konsole.log("selectedPaymentmethod", selectedPaymentMethod);
    setSelectedPaymentMethod(selectedPaymentMethod);
    setPaymentMethod(method);
  };
  // konsole.log("sem1",bookedSeats.data.attendees.length);
  // konsole.log("sem2",attendeeObj);

  // const handlePostPayment = () => {
  //   if(selectedPaymentMethod == null){
  //     Toaster.warning("Please select payment method");
  //     return;
  //   }
  //   history.push("/AfterPaymentScreen");
  // }

  // konsole.log("attendeeObj",bookedSeats.seminarObjct.input)

  const handlePostPayment = () => {
    if (paymentMethod == "" || paymentMethod == null) {
      alert("Please select  payment method");
      return;
    } else {
      setshowPayment(true);
      setTimeout(() => {
        setshowPayment(false);
      }, 2000);
    }
  };

  const radioHandler = (status) => {
    setStatus(status);
  };
  // konsole.log("topic",bookedSeats?.post2.semUserStatusId ? "yes":"no")
  // if(bookedSeats?.post2){
  //   konsole.log("yes")
  // }
  // else{
  //   konsole.log("no")
  // }
  const PlannerBook =(data)=>{
    commonLib.setDatatoSession("DataState", JSON.stringify(data))
   
   if(data === "CLASSROOM"){
    history.push({
      pathname: '/Classroomreg',
      state : data
    
    })
  }
  else{
    history.push({
      pathname :'/AfterPaymentScreen'
    });
    // setshowPayment(true);
  }
  }
  const SendPrice =(prize,discountPrize)=>{
    setprice(prize)
    setdiscountedPrize(discountPrize)
    setshowPayment(true)
  }

  return (

  <>
    <LayoutComponent>
     {bool == true ? (
        <> 
          {/* <EventHeader subtenantId={subtenantId ?? ""} /> */}
          <div className="container d-flex justify-content-center mainMobile mt-2"style={{alignItems:"center",height:"80vh"}}>
            <div className={showData == true || showDatas == true ?  "card-deck newLine pb-3 mobileDeck":"card-deck mobileDeck pb-3"}>
              <div className={showData== true ? "card cardUse mobiles":"card cardUses mobile"}style={{marginTop:"initial"}}>
               
                <div className="card-body">
                  <h5 className="card-title text-color mb-0">Legal LifePlanning</h5>
                  <ul className="text-left text-header mt-2"> <li>Workshop Learning </li> </ul>
                  <h6 className="text-header mb-0">+</h6>
                  <ul className="text-left text-header mb-0"> <li>Legal Planning </li> </ul>

                   <span className="cursor-pointer mt-0 mb-0" onClick={()=>setshowData(!showData)}>...Read More</span>
                   {showData == true ? (
                    <>
                    {/* <ul className="text-left text-header mb-0"> <li>Workshop Learning </li> </ul>
                  <h6 className="text-header mb-0">+</h6>
                  <ul className="text-left text-header mb-0"> <li>Legal Planning </li> </ul> */}

                  <ul className="text-left ms-4 mb-0">
                     <li>Wills</li>
                     <li>Safe Harbor Trust</li>
                     <li>Transfer Deeds</li>
                     <li>Health Care POA </li>
                     <li>Financial POA</li>
                     <li>Living Will </li>
                     <li>Handling of Remains</li>
                     <li>HIPAA</li>
                     <li>MHAD</li>
                     <li>Beneficiary Change Instructions </li>
                      </ul>
                     
                    </>
                   ):("")}
                    <ul className="text-left text-header mb-2"> <li>Family Meeting</li> </ul>
                 
                    <h6 className="mt-0">Standard price of $9,950</h6>
                    <h6 className="mt-0">Seminar special discount of 25% at $7,450</h6>
                  
              
                </div>

                <div className="card-footer">
                {/* <button className="btn btn-primary newButton rounded-pill w-50"type="button"onClick={()=>SendPrice(7450)}>$7,450</button> */}
                <button className="btn btn-primary newButton rounded-pill w-50"type="button"onClick={()=>SendPrice(9950,7450)}>$7,450</button>

                 </div>
              </div>


              <div className={showDatas== true ? "card cardUse mobiles" :"card cardUses mobile"}style={{marginTop:"initial"}}>
                {/* <img src="../../s1.jpg" className="card-img-top" alt="..." /> */}
                <div className="card-body">
                  <h5 className="card-title text-color mb-0">Custom LifePlanning</h5>
                  
                  <ul className="text-left text-header mb-0"> <li>1-1 Planning with LifePlanner </li> </ul>
                  <ul className="text-left text-header ms-4 mb-0"> 
                  <li>Assessment Latter</li>
                  <li>Planning RoadMap </li>
                   </ul>
                   <ul className="text-left text-header mb-0"> <li>Legal Planning </li> </ul>
                   <span className="cursor-pointer mt-0 mb-0" onClick={()=>setshowDatas(!showDatas)}>...ReadMore</span>
                   {showDatas == true ? (
                    <>
                  
                  <ul className="text-left ms-4 mb-0">
                     <li>Wills or Trust</li>
                     <li>Safe Harbor Trust</li>
                     <li>Transfer Deeds</li>
                     <li>Health Care POA </li>
                     <li>Financial POA</li>
                     <li>Living Will </li>
                     <li>Handling of Remains</li>
                     <li>HIPAA</li>
                     <li>MHAD</li>
                     <li>Beneficiary Change Instructions </li>
                      </ul>
                      
                    </>
                    ):("")}
                    <ul className="text-left text-header mb-0"> 
                      <li>Family Meeting</li> 
                      <li>Review RoadMap</li> 
                      </ul>
                      <ul className="text-left text-headers mb-0">
                        <li>Project Manager</li>
                        <li className="ms-4">Health Housing Finance</li>
                        <li className="mb-1">Closing Meeting</li>
                      </ul>
                      <h6 className="mt-0">Standard price of $17,950</h6>
                    <h6 className="mt-0">Seminar special discount of 25% at $13,450</h6>
                 
                </div>
                <div className="card-footer">
                {/* <button className="btn btn-primary newButton rounded-pill w-50"type="button" onClick={()=>SendPrice(13450)}>$13,450</button> */}
                <button className="btn btn-primary newButton rounded-pill w-50"type="button" onClick={()=>SendPrice(17950,13450)}>$13,450</button>
                </div>
              </div>
            </div>
          </div>
          {/* {showPayment ? (
          <Payment
            // selectedUser={bookedSeats}
            // orderId={orderId}
            // paymentMethod={paymentMethod}
            price={price}
            discountedPrize={discountedPrize}
            attendeeObj={attendeeObj}
            seminarObj={seminarObj}
          />
        ) : (
          ""
        )} */}
          </>
        ):(
          <>
          
          <div className="container-fluid pb-3">
        <div className="row">
          <div className="col-sm-6 mt-2">
            
            <h3 className="">
              Topic :{" "}
              {bookedSeats?.post2
                ? bookedSeats?.semObj.seminarTopic
                : seminarObj?.seminarTopic}
          
            </h3>
          </div>
       
        </div>
        <div className="container-fluid d-flex justify-content-center align-items-center">
          <div className="col-sm-12 bg-white d-flex flex-column flex-md-row p-0 p-md-5">
            <div className="col-xs-4 col-xl-5 border-end  fillDisplay">
              <div class="ms-4 m-ms-md-0 userMobile1">
                <dl className="user-Details-Div userMobile2 ">
                  <div className="userMobile">
                  <h3>
                    <b>User Details</b>
                  </h3>
                  <div className="dt">
                    First name :{" "}
                    <span>
                      {bookedSeats?.post2
                        ? bookedSeats?.post.firstName
                        : attendeeObj?.attendeeFullName}
                  
                    </span>{" "}
                  </div>
                  <dd id="lblName"></dd>
                  <div className="dt">
                    Email :{" "}
                    <span>
                      {bookedSeats?.post2
                        ? bookedSeats?.post.emailAddress
                        : attendeeObj?.attendeeEmailAddress}
               
                    </span>
                  </div>
                  <dd id="lblId"></dd>
                  <div className="dt">
                    Cell :{" "}
                    <span>
                      {bookedSeats?.post2
                        ? bookedSeats?.post.countryCode +
                          commonLib.formatPhoneNumber(
                            bookedSeats?.post.phoneNumber
                          )
                        : commonLib.formatPhoneNumber(
                            attendeeObj?.attendeeMobileNo
                          )}
            
                    </span>
                  </div>
                  <dd id="lblId"></dd>
               
                  <dd id="lblAmenities"></dd>
                  </div>
              
                </dl>
              </div>
            </div>
            <div className="col-xl-3 col-md-6  col-lg-8 ps-4 mobileScreen">
              <h4 className="mobile1">Choose a Services below</h4>
            
              <div className='d-flex col-xs-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 mobileCardDiv' style={{flexFlow:"none"}}>
                                <Col className='col-xl-12 col-md-10 col-sm-5 col-xs-12 col-lg-5 me-2 mb-3 mobileCard1 ' style={{backgroundColor:"#720c20"}}
                                onClick={()=> PlannerBook("PLANNER")} >
                                    {/* <Link to="customplanningslot"> */}
                                    <div className='d-flex  customplanning-button'>
                                        <div className='p-1' style={{ width: "90px" }}>
                                            <img src="custommobile.png" className='img-fluid' />
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center' style={{width:"190px"}}>
                                            <h6 className=' text-white'>Appointment Booking</h6>
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </Col>
                                <Col className='col-xl-12 col-md-10 col-sm-5 col-xs-12 col-lg-5 mb-3'style={{backgroundColor:"#720c20"}}
                                 onClick={()=> PlannerBook("CLASSROOM")}>
                                {/* <Link to="workshopMobile"> */}
                                    <div className='d-flex  customplanning-button'>
                                        <div className='p-1' style={{ width: "90px" }}>
                                            <img src="classroommobile.png" className='img-fluid' />
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center' style={{width:"190px"}}>
                                            <h6 className=' text-white'>Workshop</h6>
                                        </div>
                                    </div>
                                    {/* </Link> */}
                                </Col>
                            </div>
                 
            
            </div>
          </div>
        </div>
         </div>
         
        
          </>
          )}

{showPayment ? (
          <Payment
            // selectedUser={bookedSeats}
            // orderId={orderId}
            // paymentMethod={paymentMethod}
            price={price}
            discountedPrize={discountedPrize}
            attendeeObj={attendeeObj}
            seminarObj={seminarObj}
            rateCardPrize={props?.location?.state?.rateCardPrize}
          />
        ) : (
          ""
        )}
           </LayoutComponent>
  </>

    
  );
}

import "./Dashboard.css";
import { useCallback, useContext, useEffect, useState } from "react";
import konsole from "../../network/konsole";
import Services from '../../network/Services'
import commonLib from "../../control/commonLib";
import { useHistory, useParams } from "react-router-dom";
import { UserContext } from "../../App";
import Toaster from "../../Toaster/Toaster";
import { defaultIPAddress, selfCheckingCheckedIn, selfCheckInId } from "../../control/Constant";
import Payment from "../SelfPlanner/VenuePayment/Payment";
import EventHeader from "../SeminarListingHeader";
import SeminarListingFooter from "../SeminarListingFooter";

const Dashboard = (props) => {
  konsole.log("props at App", props);
  const { setSubtenantLogo, setLoader } = useContext(UserContext);
  const [showPayment,setshowPayment] = useState(false)
  const [attendeeObjData,setAttendeeObjData] = useState("")
  const [seminarObjData, setSeminarObjData] = useState("")
  const [bool, setbool] = useState(false)
  const qrlQuery = props?.location?.search;
  const subtentantId = commonLib.GetQueryValues(qrlQuery, 'subtenantId');
  const uId = commonLib.GetQueryValues(qrlQuery,'UID');
  const seminarId = commonLib.GetQueryValues(qrlQuery, 'seminarId');
  const [price, setprice] = useState(null)
  const [discountedPrize, setdiscountedPrize] = useState(null)
  const stage = commonLib.GetQueryValues(qrlQuery, 'Stage');
  commonLib.setDatatoSession("stage",JSON.stringify(stage))
  const [showData, setshowData] = useState(false)
  const [showDatas, setshowDatas] = useState(false)
  const history = useHistory();
  

  

konsole.log("get",stage)
  useEffect(() => {
    
    if (uId !== undefined && uId !== null && uId !== "") {
      getSeminarAttendeeAccessLink(uId, defaultIPAddress);
    }
    else if ((subtentantId !== undefined && subtentantId !== null) && (seminarId !== undefined || seminarId !== null)) {
      getSubtenantDetail(subtentantId, true);
    }
    
    return () => {
      // konsole.clear();
    }
  }, [])


  const subtenantRateCard = (subtenantRateCardId) => {
    const promises = Services.getSubtenantSeminarRateCard(subtenantRateCardId)
    promises.then((res) => {
      // setrateCardPrize(res.data.data[0]?.endSubscriptionAmt)
      let rateCardPrize = res?.data?.data?.length > 0 && res.data.data[0]?.endSubscriptionAmt
      redirectUsingStage(stage,rateCardPrize);
      // setcoOrdinatorList(res.data.data)
    }).catch((err) => {
      redirectUsingStage(stage,"350")
    })
  }

  
  const getSeminarAttendeeAccessLink = (uniqueKey, clientIPAddress) => {
    setLoader(true);
    // konsole.log("upt",uniqueKey)
    const promises = Services.getSeminarAttendeeAccessLink(uniqueKey)
    promises.then(res => {
      setLoader(false);
       konsole.log('seminar Token Key111', res.data.data);
      const accessObj = res.data.data;
      const accessObj1 = res.data.data;
      commonLib.setSessionStoarge('venueObj2', JSON.stringify(accessObj1));
      saveInStorage(accessObj,accessObj1);
    })
      .catch(err => {
        konsole.log("err", err.response);
        Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  const getSubtenantDetail = (subtenantId, isActive) => {
  
    setLoader(true);
    const promises = Services.getSubtenantDetail(subtenantId, isActive);
    promises.then(res => {
      setLoader(false);
      konsole.log('subtenantResObj', res);
      const subtenantRes = res.data.data[0];
      setSubtenantLogo(subtenantRes.subtenantLogoUrl);
      const seminarEventObj = commonLib.getEventListSubtenantObj(subtenantRes);
     konsole.log("aaw", subtenantId, seminarId)
      getSeminarPublisherDetail(subtenantId, true, seminarId, seminarEventObj,"UPCOMING");
    }).catch(err => {
      konsole.log("error", err.response);
      setLoader(false);
    })
  }

  const getSeminarPublisherDetail = (isActive, subtenantId, seminarId, seminarSubtenantObj,seminarType) => {
    setLoader(true);
    const promises = Services.getSeminarPublisherDetail(isActive, subtenantId, seminarId,seminarType);
    promises.then(res => {
      setLoader(false);
      konsole.log('seminar Token Key', res);
      const seminarResObj = res.data.data[0];
      let seminarEventObj = commonLib.getSeminarObj(seminarResObj);
      let venueObj = commonLib.getVenueObj(seminarResObj);
      seminarEventObj = { ...seminarEventObj, ...seminarSubtenantObj }
      commonLib.setSessionStoarge('seminarObj', JSON.stringify(seminarEventObj));
      commonLib.setSessionStoarge('venueObj', JSON.stringify(venueObj));
      
     
    
      redirectUsingStage(stage);
      

    })
      .catch(err => {
        konsole.log("err", err.response);
        Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        setLoader(false);
        konsole.log("finish");
      })

  }

  const saveInStorage = (accessObj,accessObj1) => {
    const venueObj = commonLib.getVenueObj(accessObj);
    const seminarObj = commonLib.getSeminarObj(accessObj);
    const attendeeObj = commonLib.getSeminarAttendee(accessObj);
   

    Services.getDeviceType((deviceType) => {
      if (deviceType == "Mobile") {
        seminarObj['logoUrl'] = accessObj.subtenantMobileLogoURL;
        setSubtenantLogo(accessObj.subtenantMobileLogoURL);
      }
      else if (deviceType == "Desktop") {
        seminarObj['logoUrl'] = accessObj.subtenantDesktopLogoURL;
        setSubtenantLogo(accessObj.subtenantDesktopLogoURL);
      }
      setAttendeeObjData(attendeeObj)
      setSeminarObjData(seminarObj)
      commonLib.setSessionStoarge("venueObj", JSON.stringify(venueObj));
      commonLib.setSessionStoarge("seminarObj", JSON.stringify(seminarObj));
      commonLib.setSessionStoarge("attendeeObj", JSON.stringify(attendeeObj));


      const subtenantId = seminarObj.seminarSubtenantId;
      const seminarId = seminarObj.seminarId;
      const attendeeEmailAddress = attendeeObj?.attendeeEmailAddress

      if(stage == "CHECKIN"){
        history.replace('/selfChecking')
      } else if(stage == "qrcode"){ 
        let urlCode = {uid:uId,Stage:stage}
        let uniCode = `UID=${uId}&Stage=${stage}`
        history.push({
          pathname : "/SelfcheckbyQr",
          state : urlCode

        })

      }
      else{
    
        subtenantRateCard(accessObj1?.subtenantRatecardId)
        // redirectUsingStage(stage);
      }

    })
  }



  

  const handleWalkInCheckInUser = (seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy) => {
    setLoader(true);
    const promises = Services.updateSeminarAttendeeInfo(seminarId, seminarAttendeeId, attendeeUserId, semUserStatusId, upsertedBy);
    promises.then(res => {
      setLoader(false);
      konsole.log('registered Checkin res', res);
      commonLib.setDatatoSession("GetResponse", JSON.stringify(res.data.data))
      const selfWalkInCheckInObj = res.data.data.attendees[0];

      const bookedSeats = { sheets: selfWalkInCheckInObj.allottedSeats };
      history.push({
        pathname: '/Congratulations',
        state: bookedSeats
      })
    })
      .catch(err => {
        konsole.log("errs", err.response);
        Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  const validateSeminarAttendee = (subtenantId, seminarId, contact, status) => {
    setLoader(true);
    const promises = Services.validateSeminarAttendee(subtenantId, seminarId, contact, status);
    promises.then(res => {
      setLoader(false);
      konsole.log("responsvie", res);
      const validateUserResponse = res.data.data[0];
      const attendeeSeminarId = validateUserResponse.seminarAttendeeId;
      const attendeeUserId = validateUserResponse.seminarAttendeeUserId;

      handleWalkInCheckInUser(seminarId, attendeeSeminarId, attendeeUserId, selfCheckInId, attendeeUserId);
    })
      .catch(err => {
        konsole.log("errd", err.response);
        Toaster.error(err.response.data.messages[0])
      }).finally(fin => {
        konsole.log("finish");
        setLoader(false);
      })
  }





  const redirectUsingStage = (stage,rateCardPrize) => {
    const paymentData ={attendeeObjData:attendeeObjData,seminarObjData:seminarObjData,rateCardPrize:"350"}
    konsole.log("redirect call",stage);
    switch(stage){
      case "CHECKIN":
        history.replace('/selfChecking')
        break;
      case 'PLANNER':
        history.replace('/AfterPaymentScreen');
        setbool(true)
        // setshowPayment(true)
      break;
      case 'CLASSROOM':
        history.replace('/Classroomreg');
      break;
      case 'PLANNER_CLASSROOM':
        // history.replace('/VenuePayment');
        history.push({
          pathname: '/UserBooking',
          state : paymentData
        
        })
      break;
    }
  }

const SendPrice =(prize,discountPrize)=>{
 
  setprice(prize)
  setdiscountedPrize(discountPrize)
  setshowPayment(true)
}

  
  return (
    <div>
      {bool == true ? (
        <> 
          <EventHeader subtenantId={subtentantId} />
          <div className="container d-flex justify-content-center mainMobile mt-2"style={{alignItems:"center",height:"80vh"}}>
            <div className={showData == true || showDatas == true ?  "card-deck newLine pb-3 mobileDeck":"card-deck mobileDeck pb-3"}>
              <div className={showData== true ? "card cardUse mobiles":"card cardUses mobile"}style={{marginTop:"initial"}}>
               
                <div className="card-body">
                  <h5 className="card-title text-color mb-0">Legal LifePlanning</h5>
                  <ul className="text-left text-header mt-2"> <li>Workshop Learning </li> </ul>
                  <h6 className="text-header mb-0">+</h6>
                  <ul className="text-left text-header mb-0"> <li>Legal Planning </li> </ul>

                   <span className="cursor-pointer mt-0 mb-0" onClick={()=>setshowData(!showData)}>...Read More</span>
                   {showData == true ? (
                    <>
                    {/* <ul className="text-left text-header mb-0"> <li>Workshop Learning </li> </ul>
                  <h6 className="text-header mb-0">+</h6>
                  <ul className="text-left text-header mb-0"> <li>Legal Planning </li> </ul> */}

                  <ul className="text-left ms-4 mb-0">
                     <li>Wills</li>
                     <li>Safe Harbor Trust</li>
                     <li>Transfer Deeds</li>
                     <li>Health Care POA </li>
                     <li>Financial POA</li>
                     <li>Living Will </li>
                     <li>Handling of Remains</li>
                     <li>HIPAA</li>
                     <li>MHAD</li>
                     <li>Beneficiary Change Instructions </li>
                      </ul>
                     
                    </>
                   ):("")}
                    <ul className="text-left text-header mb-2"> <li>Family Meeting</li> </ul>
                 
                    <h6 className="mt-0">Standard price of $9,950</h6>
                    <h6 className="mt-0">Seminar special discount of 25% at $7,450</h6>
                  
              
                </div>

                <div className="card-footer">
                <button className="btn btn-primary newButton rounded-pill w-50"type="button"onClick={()=>SendPrice(9950,7450)}>$7,450</button>
                 </div>
              </div>


              <div className={showDatas== true ? "card cardUse mobiles" :"card cardUses mobile"}style={{marginTop:"initial"}}>
                {/* <img src="../../s1.jpg" className="card-img-top" alt="..." /> */}
                <div className="card-body">
                  <h5 className="card-title text-color mb-0">Custom LifePlanning</h5>
                  
                  <ul className="text-left text-header mb-0"> <li>1-1 Planning with LifePlanner </li> </ul>
                  <ul className="text-left text-header ms-4 mb-0"> 
                  <li>Assessment Latter</li>
                  <li>Planning RoadMap </li>
                   </ul>
                   <ul className="text-left text-header mb-0"> <li>Legal Planning </li> </ul>
                   <span className="cursor-pointer mt-0 mb-0" onClick={()=>setshowDatas(!showDatas)}>...ReadMore</span>
                   {showDatas == true ? (
                    <>
                  
                  <ul className="text-left ms-4 mb-0">
                     <li>Wills or Trust</li>
                     <li>Safe Harbor Trust</li>
                     <li>Transfer Deeds</li>
                     <li>Health Care POA </li>
                     <li>Financial POA</li>
                     <li>Living Will </li>
                     <li>Handling of Remains</li>
                     <li>HIPAA</li>
                     <li>MHAD</li>
                     <li>Beneficiary Change Instructions </li>
                      </ul>
                      
                    </>
                    ):("")}
                    <ul className="text-left text-header mb-0"> 
                      <li>Family Meeting</li> 
                      <li>Review RoadMap</li> 
                      </ul>
                      <ul className="text-left text-headers mb-0">
                        <li>Project Manager</li>
                        <li className="ms-4">Health Housing Finance</li>
                        <li className="mb-1">Closing Meeting</li>
                      </ul>
                      <h6 className="mt-0">Standard price of $17,950</h6>
                    <h6 className="mt-0">Seminar special discount of 25% at $13,450</h6>
                 
                </div>
                <div className="card-footer">
                <button className="btn btn-primary newButton rounded-pill w-50"type="button" onClick={()=>SendPrice(17950,13450)}>$13,450</button>
                </div>
              </div>
            </div>
          </div>
        
          </>
        ):("")}
     {showPayment ? (
          <Payment
            // selectedUser={bookedSeats}
            // orderId={orderId}
            // paymentMethod={paymentMethod}
            price={price}
            discountedPrize={discountedPrize}
            attendeeObj={attendeeObjData}
            seminarObj={seminarObjData}
            // rateCardPrize={rateCardPrize}
          />
        ) : (
          ""
        )}
    </div>
  );
};

export default Dashboard;
